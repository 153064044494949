import { Flex, Text } from '@chakra-ui/react';
import { Button, TrashIcon } from '@hydrogrid/design-system';
import { Dialog } from '../../../../../design-system/library/components/Dialog/Dialog';
import { useUnassignPlant } from '../../../../common/api/hooks/internalDataHooks';

type Props = {
  onClose: () => void;
  portfolioId: string;
  plantId: string;
  name: string;
  isLast: boolean;
};

export function UnassignPlantViewDialog({ onClose, portfolioId, plantId, name, isLast }: Props) {
  const unassignPlant = useUnassignPlant(portfolioId, plantId);
  const onValidSubmit = async () => {
    return await new Promise<boolean>(resolve => {
      unassignPlant.mutate(
        { portfolioId, plantId },
        {
          onSuccess: () => {
            onClose();
            resolve(true);
          },
          onError: () => resolve(false)
        }
      );
    });
  };

  return (
    <Dialog title={`Unassign ${name}`} isOpen={true} onClose={onClose}>
      <Text fontSize="md">
        {isLast ? (
          <>
            Since this is the last Plant View attached to this plant, when deleting this Plant View, the whole Plant will also be unassigned
            from the Portfolio.
            <Text fontSize="md" mt="2" fontWeight="bold">
              Are you sure you want to unassign this Plant View?
            </Text>
          </>
        ) : (
          'Are you sure you want to unassign this Plant View?'
        )}
      </Text>

      <Flex justify="flex-end" gap={3} mt="6">
        <Button onClick={onClose} variant="outline" color="secondary">
          Cancel
        </Button>
        <Button leftIcon={<TrashIcon />} data-testid={`${plantId}-confirm-unassign`} onClick={onValidSubmit} colorScheme="error">
          Unassign
        </Button>
      </Flex>
    </Dialog>
  );
}
