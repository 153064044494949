import type { BoxProps } from '@chakra-ui/react';
import { useMemo } from 'react';
import { usePhysicalPlantList } from '../../../../common/api/hooks/CommonDataHooks';
import { isShadowPlant } from '../../../../common/business-logic/plantTypes';
import { MapCanvas } from '../../../../components/Map/MapCanvas';
import { MapLegend } from '../../../../components/Map/MapLegend';
import { MapMarker } from '../../../../components/Map/MapMarker';
import { PlantMarker, type MapMarkerName } from '../../../../components/Map/markers';

interface PlantsMapProps extends Omit<BoxProps, 'padding'> {
  portfolioId: string;

  highlightedPlant?: string | undefined;
  onSelectPlant: (name: string) => void;
  onHoverPlant: (name: string | undefined) => void;
}

export function PlantsMap({ portfolioId, highlightedPlant, onSelectPlant, onHoverPlant, ...props }: PlantsMapProps) {
  const plantList = usePhysicalPlantList(portfolioId);

  const markers = useMemo(() => {
    if (!plantList.isSuccess || plantList.data?.length === 0) return [];

    const plantMarkers = plantList.data?.map(plant => {
      const { internal_id, name, active, avg_gps_latitude_decimal, avg_gps_longitude_decimal } = plant;

      if (avg_gps_latitude_decimal === undefined || avg_gps_longitude_decimal === undefined) return;

      return (
        <MapMarker
          key={internal_id}
          lat={avg_gps_latitude_decimal}
          lng={avg_gps_longitude_decimal}
          icon={getMarkerForPlant({ portfolioId, isActive: active, id: internal_id })}
          scale={highlightedPlant === name ? 1.3 : 1}
          showOnTop={highlightedPlant === name}
          onMouseEnter={() => onHoverPlant(name)}
          onMouseLeave={() => onHoverPlant(undefined)}
          onClick={() => onSelectPlant(internal_id)}
        />
      );
    });

    return plantMarkers;
  }, [plantList, portfolioId, highlightedPlant, onHoverPlant, onSelectPlant]);

  return (
    <MapCanvas {...props}>
      <MapLegend>
        <LegendForPortfolioView />
      </MapLegend>
      {plantList.isSuccess && markers}
    </MapCanvas>
  );
}

function getMarkerForPlant({ portfolioId, id: plantId, isActive }: { portfolioId: string; id: string; isActive: boolean }): MapMarkerName {
  if (isShadowPlant({ portfolioId, plantId })) {
    return 'plantShadow';
  }

  if (!isActive) {
    return 'plantSecondary';
  }

  return 'plantPrimary';
}

function LegendForPortfolioView() {
  return (
    <ul>
      <li>
        <PlantMarker color="primary" /> Plant is active
      </li>
      <li>
        <PlantMarker color="secondary" /> Plant is inactive
      </li>
    </ul>
  );
}
