import { type EffectCallback, useEffect, useRef } from 'react';

/** Like useEffect, but only called once. */
export function useEffectOnce(functionToCall: EffectCallback) {
  const initialized = useRef(false);
  const cleanupRef = useRef<() => void>();
  const functionRef = useRef(functionToCall);

  functionRef.current = functionToCall;

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    const cleanup = functionRef.current();
    if (cleanup) {
      cleanupRef.current = cleanup;
    }
  }, []);

  useEffect(() => {
    return () => {
      cleanupRef.current?.();
      cleanupRef.current = undefined;
      initialized.current = false;
    };
  }, []);
}
