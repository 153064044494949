import { Flex, Text } from '@chakra-ui/react';
import { ArrowLeftIcon } from '@hydrogrid/design-system';
import { NavLink, type To } from 'react-router-dom';

interface BackButtonBasicProps {
  text?: string;
  color?: 'primary' | 'accent';
}

interface BackButtonLinkProps extends BackButtonBasicProps {
  link: To | undefined;
}

interface BackButtonOnClickProps extends BackButtonBasicProps {
  handleClick: () => void;
}

export function BackButton(props: BackButtonLinkProps | BackButtonOnClickProps) {
  const { text = 'Back', color = 'primary' } = props;

  return (
    <Flex
      as={NavLink}
      align="center"
      gap={1}
      to={'link' in props ? props.link : undefined}
      onClick={'handleClick' in props ? props.handleClick : undefined}
      width="fit-content"
    >
      <ArrowLeftIcon size="small" color={color} />
      <Text color={color}>{text}</Text>
    </Flex>
  );
}
