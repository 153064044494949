import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type CoreHeaderGroup,
  type Row,
  type SortingState,
  type VisibilityState
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

interface Props<Data, Filter> {
  data: Array<Data>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<ColumnDef<Data, any>>;
  columnVisibility?: VisibilityState;
  filter?: Filter;
  defaultSorting?: SortingState;
  onFilter?: (row: Row<Data>, columnId: string, filter: Filter) => boolean;
}

export function useTable<Data, Filter>({
  data,
  columns,
  columnVisibility,
  filter: outerFilter,
  defaultSorting = [],
  onFilter
}: Props<Data, Filter>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);

  // memoized filter so that TanStack Table doesnt rerender endlessly
  const filter = useMemo(() => {
    return outerFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(outerFilter)]);

  const table = useReactTable({
    state: {
      globalFilter: filter,
      sorting,
      columnVisibility: columnVisibility ?? (undefined as never)
    },
    onSortingChange: setSorting,
    globalFilterFn: onFilter ?? (undefined as never),
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const isSortingActive = sorting.length > 0;
  const { rows } = table.getRowModel();
  const isEmpty = rows.length === 0;

  const headerGroups = table.getHeaderGroups();
  let headerColumns: CoreHeaderGroup<Data>['headers'] = [];
  if (headerGroups.length > 0) {
    headerColumns = headerGroups[0].headers;
  }

  const footerGroups = table.getFooterGroups();
  let footerColumns: CoreHeaderGroup<Data>['headers'] = [];
  if (footerGroups.length > 0) {
    footerColumns = footerGroups[0].headers;
  }

  return {
    table,
    rows,
    isEmpty,
    isSortingActive,
    headerColumns,
    footerColumns
  };
}
