import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgReservoirPrimary = (props, ref) => /* @__PURE__ */ jsx("svg", { width: 45, height: 45, viewBox: "0 0 45 45", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props, children: /* @__PURE__ */ jsxs("g", { fill: "#0BB7A0", "data-dynamic-fill": "#0BB7A0", children: [
  /* @__PURE__ */ jsx("path", { stroke: "#0003", d: "M22.414 3.164c-7.961 0-14.437 6.477-14.437 14.438 0 10.65 13.32 23.31 13.887 23.844a.803.803 0 0 0 1.1 0c.567-.534 13.888-13.193 13.888-23.844 0-7.961-6.477-14.438-14.438-14.438Z" }),
  /* @__PURE__ */ jsx("path", { fill: "#fff", d: "M22.5 30.5c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13Z" }),
  /* @__PURE__ */ jsx("path", { d: "M33.615 17.5a.248.248 0 0 1 .248.247 11.366 11.366 0 0 1-22.726 0 .248.248 0 0 1 .247-.247h22.231Z" }),
  /* @__PURE__ */ jsx("path", { stroke: "#fff", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 0.617, d: "M16.351 19.738c1.23 0 1.23.495 2.46.495s1.23-.495 2.46-.495 1.23.495 2.46.495c1.229 0 1.229-.495 2.459-.495s1.23.495 2.46.495M16.35 21.257c1.23 0 1.23.495 2.46.495s1.23-.495 2.46-.495 1.23.495 2.46.495c1.229 0 1.229-.495 2.459-.495s1.23.495 2.46.495M16.35 22.777c1.23 0 1.23.495 2.46.495s1.23-.495 2.46-.495 1.23.495 2.46.495c1.229 0 1.229-.495 2.459-.495s1.23.495 2.46.495" })
] }) });
const ForwardRef = forwardRef(SvgReservoirPrimary);
export default ForwardRef;
