import { toast } from '@hydrogrid/design-system';
import { stringToCapitalize } from '@hydrogrid/utilities/string/stringCase';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiError, isProblemJson } from '../ApiError';
import { api } from '../InsightApi';
import type { ComponentType, Email, NewAccount, Portfolio } from '../generated-client/Schemas';
import * as queries from '../queries';

export function useAddUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['addInsightUser'],
    mutationFn: (body: NewAccount) => {
      return api.internal.dashboard.account.add({ body });
    },
    onSuccess(_response) {
      toast({ status: 'success', description: 'The user was saved successfully' });

      const queryKey = queries.insightUsersList.getQueryKey();
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The user could not be saved due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'User could not be saved' });
    }
  });
}

export function useAllPlants() {
  return useQuery(queries.allPlantsList());
}

export function useComponents(portfolioId: string, plantId: string) {
  return useQuery(queries.listComponents(portfolioId, plantId));
}

export function useApiPermissionsPortfolio(accountId: string, portfolio_id: string) {
  return useQuery(queries.apiPermissionsPortfolio(portfolio_id, accountId));
}

export function useAssignPlant() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['assignPlant'],
    mutationFn: ({ portfolioId, plantId }: { portfolioId: string; plantId: string }) => {
      return api.internal.dashboard.portfolio.assignPlant({ portfolio_id: portfolioId, plant_id: plantId });
    },
    onSuccess(_response, { portfolioId }) {
      toast({ status: 'success', description: 'The plant was assigned successfully' });

      const queryKey = queries.physicalPlantList.getQueryKey(portfolioId);
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The plant could not be assigned due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Plant could not be assigned' });
    }
  });
}

export function useShadowPlant() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['shadowPlant'],
    mutationFn: ({ plantId }: { plantId: string }) => {
      return api.internal.dashboard.setup.shadowPlant({ plant_id: plantId });
    },
    onSuccess(_response, { plantId }) {
      toast({ status: 'success', description: 'The shadow plant was created successfully' });

      const queryKey = queries.basicArrangement.getQueryKey(plantId);

      return queryClient.invalidateQueries({ queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The shadow plant could not be created due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Shadow plant could not be created' });
    }
  });
}

export function useAddApiUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['addApiUser'],
    mutationFn: ({
      body
    }: {
      body: {
        email: Email;
        name: string;
        portfolio_name: string;
      };
    }) => {
      return api.dashboard.account.addApiAccount({ body });
    },
    onSuccess(_response) {
      toast({ status: 'success', description: 'The API user was saved successfully' });

      const queryKey = queries.apiUsersList.getQueryKey();
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The API user could not be saved due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'API User could not be saved' });
    }
  });
}

export function useAddPortfolio() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['addPortfolio'],
    mutationFn: ({ body }: { body: Portfolio }) => {
      return api.internal.dashboard.portfolio.add({ body });
    },
    onSuccess(_response) {
      toast({ status: 'success', description: 'The portfolio was added successfully' });

      const queryKey = queries.allPortfoliosList.getQueryKey();
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The portfolio could not be saved due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Portfolio could not be saved' });
    }
  });
}

export function useDeletePortfolio(portfolio_id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete-portfolio', portfolio_id],
    mutationFn: ({ portfolio_id }: { portfolio_id: string }) => {
      return api.internal.dashboard.portfolio.delete({ portfolio_id: portfolio_id });
    },
    onSuccess: _response => {
      toast({ status: 'success', description: 'The portfolio was deleted successfully' });

      const queryKey = queries.allPortfoliosList.getQueryKey();
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'Deleting this portfolio was not possible due to an error' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Portfolio could not be deleted' });
    }
  });
}

export function useAssignPortfolioUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['assign-portfolio'],
    mutationFn: ({ portfolioId, accountId }: { portfolioId: string; accountId: string }) => {
      return api.internal.dashboard.account.assignPortfolio({ portfolio_id: portfolioId, account_id: accountId });
    },
    onSuccess: (_response, { accountId }) => {
      const queryKey = queries.userPortfolios.getQueryKey(accountId);
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The portfolio could not be assigned due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Portfolio could not be assigned' });
    }
  });
}

/** DEV NOTE: This endpoint does not have a onError toast because it is always done
 * together with useAssignPortfolioUser and/or useChangePermissions (managed in AssignPortfolioDialog) */
export function useSetDefaultPortfolio() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['edit-default-portfolio'],
    mutationFn: ({ portfolioId, accountId }: { portfolioId: string; accountId: string }) => {
      return api.internal.dashboard.account.setDefaultPortfolio({ portfolio_id: portfolioId, account_id: accountId });
    },
    onSuccess: (_response, { accountId }) => {
      const queryKey = queries.userPortfolios.getQueryKey(accountId);
      return queryClient.invalidateQueries({ queryKey: queryKey });
    }
  });
}

/** DEV NOTE: This endpoint does not have a onError toast because it is always done
 * together with useAssignPortfolioUser and/or useSetDefaultPortfolio (managed in AssignPortfolioDialog) */
export function useChangePermissions() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['edit-portfolio-permissions'],
    mutationFn: ({ portfolioId, accountId, canWrite }: { portfolioId: string; accountId: string; canWrite: boolean }) => {
      return api.internal.dashboard.portfolio.setWritePermissionByAccountAndPortfolio({
        account_id: accountId,
        portfolio_id: portfolioId,
        body: { can_write: canWrite }
      });
    },
    onSuccess: (_response, { accountId }) => {
      const queryKey = queries.userPortfolios.getQueryKey(accountId);
      return queryClient.invalidateQueries({ queryKey: queryKey });
    }
  });
}

export function useUnassignPortfolio({ portfolioId, accountId }: { portfolioId: string; accountId: string }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['portfolioId', portfolioId, 'accountId', accountId, 'unassignPortfolio'],
    mutationFn: ({ portfolioId, accountId }: { portfolioId: string; accountId: string }) => {
      return api.internal.dashboard.account.unassignPortfolio({ portfolio_id: portfolioId, account_id: accountId });
    },
    onMutate() {
      const queryKey = ['portfolioId', portfolioId, 'accountId', accountId, 'unassignPortfolio'];
      return queryClient.cancelQueries({ queryKey });
    },
    onSuccess: (_response, { accountId }) => {
      toast({ status: 'success', description: 'The portfolio was unassigned successfully' });

      const queryKey = queries.userPortfolios.getQueryKey(accountId);
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The portfolio could not be unassigned due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Portfolio could not be unassigned' });
    }
  });
}

export function useUnassignPlant(portfolioId: string, plantId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['portfolioId', portfolioId, 'plantId', plantId, 'unassignPlant'],
    mutationFn: ({ portfolioId, plantId }: { portfolioId: string; plantId: string }) => {
      return api.internal.dashboard.portfolio.unassignPlant({ portfolio_id: portfolioId, plant_id: plantId });
    },
    onMutate() {
      const queryKey = ['portfolioId', portfolioId, 'plantId', plantId, 'unassignPlant'];
      return queryClient.cancelQueries({ queryKey });
    },
    onSuccess: (_response, { portfolioId }) => {
      toast({ status: 'success', description: 'The plant was unassigned successfully' });

      const queryKey = queries.physicalPlantList.getQueryKey(portfolioId);
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The plant could not be unassigned due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Plant could not be unassigned' });
    }
  });
}

export function useUpdatePlantIdOrName(portfolioId: string, plantViewId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['portfolioId', portfolioId, 'plantViewId', plantViewId, 'UpdatePlantIdOrName'],
    mutationFn: ({
      portfolioId,
      plantViewId,
      body
    }: {
      portfolioId: string;
      plantViewId: string;
      body: { id?: string | undefined; name?: string | undefined };
    }) => {
      return api.internal.dashboard.portfolio.updatePlantIdOrName({ portfolio_id: portfolioId, plant_id: plantViewId, body });
    },
    onMutate() {
      const queryKey = ['portfolioId', portfolioId, 'plantViewId', plantViewId, 'UpdatePlantIdOrName'];
      return queryClient.cancelQueries({ queryKey });
    },
    onSuccess: (_response, { portfolioId }) => {
      toast({ status: 'success', description: 'The Plant View was updated successfully' });

      const queryKey = queries.physicalPlantList.getQueryKey(portfolioId);

      return queryClient.invalidateQueries({ queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The Plant View could not be updated due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Plant View could not be updated' });
    }
  });
}

export function useUpdateComponent(portfolioId: string, plantViewId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['portfolioId', portfolioId, 'plantViewId', plantViewId, 'UpdateComponent'],
    mutationFn: ({
      portfolioId,
      plantViewId,
      component_type,
      component_id,
      body: { new_component_id, new_component_name }
    }: {
      portfolioId: string;
      plantViewId: string;
      component_type: string;
      component_id: string;
      body: { new_component_id: string; new_component_name: string };
    }) => {
      return api.internal.dashboard.component.updateComponent({
        portfolio_id: portfolioId,
        plant_id: plantViewId,
        component_type,
        component_id,
        body: { new_component_id, new_component_name }
      });
    },
    onMutate() {
      const queryKey = ['portfolioId', portfolioId, 'plantViewId', plantViewId, 'UpdateComponent'];
      return queryClient.cancelQueries({ queryKey });
    },
    onSuccess: async (_response, { portfolioId }) => {
      toast({ status: 'success', description: 'The component was updated successfully' });

      const topologyKey = queries.topology.getQueryKey(portfolioId, plantViewId);
      const componentKey = queries.listComponents.getQueryKey(portfolioId, plantViewId);

      await queryClient.invalidateQueries({ queryKey: [...topologyKey] });
      await queryClient.invalidateQueries({ queryKey: [...componentKey] });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The component could not be updated due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'The component could not be updated' });
    }
  });
}

export function useRemoveComponent(portfolioId: string, plantViewId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['portfolioId', portfolioId, 'plantViewId', plantViewId, 'RemoveComponent'],
    mutationFn: ({
      portfolioId,
      plantViewId,
      component_type,
      component_id
    }: {
      portfolioId: string;
      plantViewId: string;
      component_type: string;
      component_id: string;
    }) => {
      return api.internal.dashboard.plant.removeComponent({
        portfolio_id: portfolioId,
        plant_id: plantViewId,
        component_type,
        component_id
      });
    },
    onMutate() {
      const queryKey = ['portfolioId', portfolioId, 'plantViewId', plantViewId, 'RemoveComponent'];
      return queryClient.cancelQueries({ queryKey });
    },
    onSuccess: async (_response, { portfolioId, plantViewId }) => {
      toast({ status: 'success', description: 'The component was removed successfully' });

      const topologyKey = queries.topology.getQueryKey(portfolioId, plantViewId);
      const componentKey = queries.listComponents.getQueryKey(portfolioId, plantViewId);

      await queryClient.invalidateQueries({ queryKey: topologyKey });
      await queryClient.invalidateQueries({ queryKey: componentKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The component could not be removed due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Component could not be removed' });
    }
  });
}

export function useAssignComponent(portfolioId: string, plantViewId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['portfolioId', portfolioId, 'plantViewId', plantViewId, 'AddComponent'],
    mutationFn: ({
      portfolioId,
      plantViewId,
      component_type,
      component_id
    }: {
      portfolioId: string;
      plantViewId: string;
      component_type: string;
      component_id: string;
    }) => {
      return api.internal.dashboard.plant.addComponent({
        portfolio_id: portfolioId,
        plant_id: plantViewId,
        body: {
          type: stringToCapitalize(component_type) as ComponentType,
          internal_id: component_id
        }
      });
    },
    onMutate() {
      const queryKey = ['portfolioId', portfolioId, 'plantViewId', plantViewId, 'AddComponent'];
      return queryClient.cancelQueries({ queryKey });
    },
    onSuccess: async (_response, { portfolioId, plantViewId }) => {
      toast({ status: 'success', description: 'The component was assigned successfully' });

      const topologyKey = queries.topology.getQueryKey(portfolioId, plantViewId);
      const componentKey = queries.listComponents.getQueryKey(portfolioId, plantViewId);

      await queryClient.invalidateQueries({ queryKey: [...topologyKey] });
      await queryClient.invalidateQueries({ queryKey: [...componentKey] });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The component could not be assigned due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Component could not be assigned' });
    }
  });
}

export function useSetApiPermissions() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['setApiPermissions'],
    mutationFn: ({
      portfolioId,
      accountId,
      plantId,
      body
    }: {
      portfolioId: string;
      accountId: string;
      plantId: string;
      body: string[];
    }) => {
      return api.internal.dashboard.permissions.setPermissions({
        portfolio_id: portfolioId,
        account_id: accountId,
        plant_id: plantId,
        body
      });
    },
    onSuccess: (_response, { portfolioId, accountId }) => {
      toast({ status: 'success', description: 'The permissions were saved successfully' });

      const queryKey = queries.apiPermissionsPortfolio.getQueryKey(portfolioId, accountId);
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'The permissions could not be saved due to an error.' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'Permissions could not be saved' });
    }
  });
}

export function useDeleteUser(accountId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete-user', accountId],
    mutationFn: ({ accountId }: { accountId: string }) => {
      return api.internal.dashboard.account.delete({ account_id: accountId });
    },
    onSuccess: _response => {
      toast({ status: 'success', description: 'The user was deleted successfully' });

      const queryKey = queries.insightUsersList.getQueryKey();
      return queryClient.invalidateQueries({ queryKey: queryKey });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: 'Deleting this user was not possible due to an error' });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'User could not be deleted' });
    }
  });
}
