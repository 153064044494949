import {
  AlertDescription,
  Alert as ChakraAlert,
  Icon,
  useMultiStyleConfig,
  type AlertProps as ChakraAlertProps,
  type ThemeTypings
} from '@chakra-ui/react';
import { type PropsWithChildren, type ReactNode } from 'react';
import { CheckIcon, InfoIcon, LockIcon, WarningCircleIcon, WarningTriangleIcon } from '../../icons';
import type { IconComponent } from '../../icons/defineIconComponent';

export type AlertStatus = 'success' | 'info' | 'warning' | 'error' | 'locked';

export const AlertStatuses: Record<AlertStatus, { icon: IconComponent; colorScheme: ThemeTypings['colorSchemes'] }> = {
  info: { icon: InfoIcon, colorScheme: 'info' },
  warning: { icon: WarningTriangleIcon, colorScheme: 'warning' },
  success: { icon: CheckIcon, colorScheme: 'primary' },
  error: { icon: WarningCircleIcon, colorScheme: 'error' },
  locked: { icon: LockIcon, colorScheme: 'secondary' }
};

export interface AlertProps extends Omit<ChakraAlertProps, 'status'> {
  children?: ReactNode;
  variant?: 'subtle' | 'solid';
  status?: AlertStatus;
  noIcon?: boolean;
}

export function Alert({ children, status = 'success', noIcon = false, ...props }: PropsWithChildren<AlertProps>) {
  const { variant } = props;
  const styles = useMultiStyleConfig('Alert', { variant, colorScheme: AlertStatuses[status].colorScheme, status });

  return (
    <ChakraAlert {...props} colorScheme={AlertStatuses[status].colorScheme}>
      {!noIcon && <Icon as={AlertStatuses[status].icon} __css={styles.icon} />}
      <AlertDescription>{children}</AlertDescription>
    </ChakraAlert>
  );
}
