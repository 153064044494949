import { Text } from '@chakra-ui/react';
import { useTable } from '@hydrogrid/design-system';
import { createColumnHelper } from '@tanstack/react-table';
import type { InExTernalComponent, InExTernalComponentList } from '../../../../common/api/generated-client/Schemas';

const columnHelper = createColumnHelper<InExTernalComponent>();

interface Props {
  components: InExTernalComponentList;
}

export function useComponentTable({ components }: Props) {
  const columns = [
    columnHelper.accessor('internal_id', {
      id: 'internal_id',
      header: () => 'Component Id',
      cell: info => {
        return <Text color="inherit">{info.getValue()}</Text>;
      }
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => 'Display Name',
      cell: info => {
        return (
          <Text isTruncated color="inherit">
            {info.getValue()}
          </Text>
        );
      }
    }),
    columnHelper.accessor('external_id', {
      id: 'external_id',
      header: () => 'External Id',
      cell: info => {
        return <Text color="inherit">{info.getValue()}</Text>;
      }
    })
  ];

  const table = useTable({
    data: components,
    columns,
    columnVisibility: {}
  });

  return table;
}
