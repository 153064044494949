import { AppTitle, DesignSystemProviders, HydrogridChakraProvider } from '@hydrogrid/design-system';
import { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { TrackAppActions } from '../../common/accessibility/AppActionsContext';
import { ProvideApi } from '../../common/api/InsightApi';
import { ProvideDefaultQueryClient } from '../../common/api/ProvideDefaultQueryClient';
import { ProvideUserPermissions } from '../../common/auth/UserPermissions';
import { ProvideHotkeyModalState } from '../../components/HotkeyModal/HotkeyModalStateContext';
import { ProvideGoogleMapsApi } from '../../components/Map/GoogleMapContext';
import { DevelopmentTools } from '../../development/DevelopmentTools';
import { CriticalErrorBoundary } from '../AppErrorBoundary/AppErrorBoundary';
import { AppRouter } from '../AppRouter/AppRouter';

function FallbackLoadingPlaceholder() {
  // TODO: Add better loading placeholders per-page.
  return <div>Loading...</div>;
}

// Dev Note: When you add providers to the app root, also add them to `TestWrapper.tsx`.

export function AppRoot() {
  // Todo: Workaround until recharts v2.13.0 where the defaultProps warning issue has been handled https://github.com/recharts/recharts/issues/4691
  useEffect(() => {
    const originalConsoleError = console.error;

    console.error = (...args: Parameters<typeof console.error>) => {
      if (typeof args[0] === 'string' && /defaultProps/.test(args[0])) {
        return;
      }

      originalConsoleError(...args);
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, []);
  return (
    <Suspense fallback={<FallbackLoadingPlaceholder />}>
      <HydrogridChakraProvider>
        <CriticalErrorBoundary>
          <ProvideUserPermissions>
            <ProvideGoogleMapsApi>
              <DesignSystemProviders>
                <AppTitle separator=" - " suffix="Hydrogrid Insight">
                  <BrowserRouter>
                    <ProvideDefaultQueryClient>
                      <ProvideApi>
                        <TrackAppActions>
                          <ProvideHotkeyModalState>
                            <DevelopmentTools />
                            <Suspense fallback={<FallbackLoadingPlaceholder />}>
                              <AppRouter />
                              <LoadingCompleted />
                            </Suspense>
                          </ProvideHotkeyModalState>
                        </TrackAppActions>
                      </ProvideApi>
                    </ProvideDefaultQueryClient>
                  </BrowserRouter>
                </AppTitle>
              </DesignSystemProviders>
            </ProvideGoogleMapsApi>
          </ProvideUserPermissions>
        </CriticalErrorBoundary>
      </HydrogridChakraProvider>
    </Suspense>
  );
}

function LoadingCompleted() {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('loading');
    }
  }, []);
  return null;
}
