import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgWaterCircleLoading = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 40, height: 40, fill: "none", viewBox: "0 0 40 40", ref, ...props, children: [
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsx("clipPath", { id: "clip", children: /* @__PURE__ */ jsx("circle", { fill: "#fff", cx: 20, cy: 20, r: 17.75 }) }),
    /* @__PURE__ */ jsx("pattern", { id: "wave", y: 0, width: 40, height: 40, patternContentUnits: "userSpaceOnUse", patternUnits: "userSpaceOnUse", children: /* @__PURE__ */ jsx("path", { fill: "#0BB7A0", d: "M40 23V3.5C37 2 33.5.383 27.662 1.138c-5.839.756-10.005 4.039-15.334 4.741C7 6.581 5 5.5 0 3.5c0-.056 0 .056 0 0V23h40Z" }) })
  ] }),
  /* @__PURE__ */ jsx("circle", { fill: "#fff", cx: 20, cy: 20, r: 19.5 }),
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip)", children: [
    /* @__PURE__ */ jsxs("rect", { fill: "url(#wave)", x: 0, y: 0, width: 160, height: 23, transform: "translate(-150 15)", fillOpacity: 0.25, children: [
      /* @__PURE__ */ jsx("animateTransform", { attributeType: "XML", attributeName: "transform", type: "translate", from: "-100 15", to: "-20 15", dur: "3.2s", repeatCount: "indefinite" }),
      /* @__PURE__ */ jsx("animateTransform", { attributeType: "XML", attributeName: "transform", type: "translate", values: "0 0; 0 1; 0 0", dur: "2s", repeatCount: "indefinite", additive: "sum" })
    ] }),
    /* @__PURE__ */ jsxs("rect", { fill: "url(#wave)", x: 0, y: 0, width: 120, height: 23, transform: "translate(-150 15)", fillOpacity: 0.5, children: [
      /* @__PURE__ */ jsx("animateTransform", { attributeType: "XML", attributeName: "transform", type: "translate", from: "-70 16", to: "-30 16", dur: "1.1s", repeatCount: "indefinite" }),
      /* @__PURE__ */ jsx("animateTransform", { attributeType: "XML", attributeName: "transform", type: "translate", values: "0 0; 0 1.5; 0 -0.5; 0 1; 0 0", dur: "4s", repeatCount: "indefinite", additive: "sum" })
    ] }),
    /* @__PURE__ */ jsxs("rect", { fill: "url(#wave)", x: 0, y: 0, width: 120, height: 23, transform: "translate(-160 17.5)", children: [
      /* @__PURE__ */ jsx("animateTransform", { attributeType: "XML", attributeName: "transform", type: "translate", from: "-80 17.5", to: "0 17.5", dur: "1.6s", repeatCount: "indefinite" }),
      /* @__PURE__ */ jsx("animateTransform", { attributeType: "XML", attributeName: "transform", type: "translate", values: "0 -1; 0 2; 0 -1", dur: "2.8s", repeatCount: "indefinite", additive: "sum" })
    ] })
  ] }),
  /* @__PURE__ */ jsx("path", { fill: "#0BB7A0", d: "M20 1.111A18.889 18.889 0 1 1 6.643 6.643 18.765 18.765 0 0 1 20 1.111ZM20 0a20 20 0 1 0 0 40 20 20 0 0 0 0-40Z" })
] });
const ForwardRef = forwardRef(SvgWaterCircleLoading);
export default ForwardRef;
