import { Box, Flex, type BoxProps } from '@chakra-ui/react';
import { Button } from '@hydrogrid/design-system';
import { GoogleMap } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState, type ReactNode } from 'react';
import { useGoogleMapsApi } from './GoogleMapContext';
import { ProvideCoordinates } from './MapMarkersContext';
import { defaultMapOptions } from './mapOptions';
import './overwriteGoogleMapsStyles.css';

interface MapCanvasProps extends BoxProps {
  defaultCenter?: { lat: number; lng: number };
  defaultZoom?: number;
  minZoom?: number;
  maxZoom?: number;
  padding?: number;
  children?: ReactNode;
}

export function MapCanvas({
  children,
  defaultCenter = { lat: 52.5, lng: 12.1 },
  defaultZoom = 4,
  minZoom = 2,
  maxZoom = 15,
  padding = 50,
  ...boxProps
}: MapCanvasProps) {
  const googleMaps = useGoogleMapsApi();
  const [drawContents, setDrawContents] = useState(false);
  const [mapApi, setMapApi] = useState<google.maps.Map>();
  const cleanup = useRef(() => {});

  defaultMapOptions.minZoom = minZoom;
  defaultMapOptions.maxZoom = maxZoom;

  const drawContentsAfterLoading = useCallback((map: google.maps.Map) => {
    setMapApi(map);

    const timeout = setTimeout(() => setDrawContents(true));
    cleanup.current = () => clearTimeout(timeout);
  }, []);

  const defaultCenterRef = useRef(defaultCenter);
  defaultCenterRef.current = defaultCenter;

  useEffect(() => {
    return () => cleanup.current();
  }, []);

  const updateMarkers = (markers: { lat: number; lng: number }[]) => {
    if (!mapApi) return;

    if (!markers.length) {
      mapApi.setCenter(defaultCenterRef.current);
      mapApi.setZoom(defaultZoom);
      return;
    }

    const bounds = new google.maps.LatLngBounds(markers[0]);
    for (const marker of markers) {
      bounds.extend(marker);
    }
    mapApi.fitBounds(bounds, padding);
  };

  // We need to use a non-module-scoped ".MapCanvas" to overwrite CSS of google maps.
  return (
    <Box
      className="MapCanvas"
      pos="relative"
      zIndex={0}
      border="1px"
      borderColor="secondary.200"
      rounded="base"
      overflow="hidden"
      sx={{ '& > div': { boxSize: '100%' } }}
      {...boxProps}
    >
      <ProvideCoordinates onUpdate={updateMarkers}>
        {googleMaps.isSuccess && (
          <GoogleMap clickableIcons={false} options={defaultMapOptions} onLoad={drawContentsAfterLoading}>
            {drawContents && children}
          </GoogleMap>
        )}
        {googleMaps.isError && (
          <Flex align="center" justify="center" flexDir="column" h="100%" gap={4}>
            Loading the map has failed
            <Button colorScheme="secondary" variant="outline" onClick={googleMaps.retryLoading}>
              Retry
            </Button>
          </Flex>
        )}
      </ProvideCoordinates>
    </Box>
  );
}
