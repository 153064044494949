import { searchStringToRegex } from '@hydrogrid/utilities/string';
import { useCallback, useMemo, useState } from 'react';

type StringProperty<T> = { [K in keyof T]: T[K] extends string ? K : never }[keyof T] & keyof T;

/**
 * Filter a list by a search term. Uses fuzzy search (see {@link searchStringToRegex}).
 */
export function useListSearch<T>(list: T[] | null | undefined, prop?: StringProperty<T>) {
  const [searchText, setSearchText] = useState('');

  const clearSearch = useCallback(() => setSearchText(''), [setSearchText]);

  const searchRegex = useMemo(() => searchStringToRegex(searchText), [searchText]);

  const matching = useMemo(() => {
    if (list && !searchText) return list;

    return (list ?? []).filter(item => {
      if (item == null) return false;

      // Search given property
      if (prop != null) {
        const value = item[prop] as string | undefined;
        return value != null && searchRegex.test(value);
      }

      // Search all properties
      for (const value of Object.values(item)) {
        if (typeof value !== 'string' && typeof value !== 'number') continue;

        if (searchRegex.test(String(value))) {
          return true;
        }
      }

      return false;
    });
  }, [list, prop, searchRegex, searchText]);

  return { matching, searchText, setSearchText, clearSearch };
}
