export function stringToCamelCase(input: string) {
  return input
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function stringToCapitalize(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

/**
 * Creates a slug for a string.
 *
 * @example
 * stringToSlug('Pineapple Pizza') -> 'pineapple-pizza'
 * stringToSlug('practiceMakesPerfect') -> 'practice-makes-perfect'
 */
export function stringToSlug(input: string) {
  return input
    .replace(/([a-z])([A-Z]+)/g, (_, lower: string, upper: string) => `${lower}-${upper.toLowerCase()}`)
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}
