import { Box, Flex, Grid, Skeleton, VisuallyHidden } from '@chakra-ui/react';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@hydrogrid/design-system';
import { repeatJsx } from '@hydrogrid/utilities/react';
import type { QueryStatus } from '@tanstack/react-query';
import { flexRender } from '@tanstack/react-table';
import type { InExTernalComponentList } from '../../../../common/api/generated-client/Schemas';
import { routes } from '../../../../common/routing/routes';
import { useAppRoute } from '../../../../common/routing/useAppRoute';
import { EmptyPlaceholder } from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import { useComponentTable } from '../hooks/useComponentTable';
import { ComponentActions } from './ComponentActions';

export default function ComponentList({
  queryStatus,
  components,
  onRetry
}: {
  queryStatus: QueryStatus;
  components: InExTernalComponentList;
  onRetry: () => void;
}) {
  const route = useAppRoute(routes.managePlantView);
  const { portfolioId, plantId } = route.params;

  const { rows, isEmpty, isSortingActive, headerColumns } = useComponentTable({ components });

  return (
    <Box overflow="hidden">
      {queryStatus === 'error' && (
        <EmptyPlaceholder message="There was an error loading the components" buttonText="Retry" onClick={onRetry} />
      )}
      {queryStatus === 'pending' && (
        <Flex mt={3} gap={3} flexDirection="column">
          <VisuallyHidden>Loading</VisuallyHidden>
          {repeatJsx(
            4,
            <Grid gap={3} role="table" gridTemplateColumns="minmax(2rem, 4fr) minmax(2rem, 6fr) minmax(2rem, 4fr) 3fr ">
              <Skeleton h={5} w="20" />
              <Skeleton h={5} w="20" />
              <Skeleton h={5} w="20" />
              <Skeleton h={5} w="20" />
            </Grid>
          )}
        </Flex>
      )}
      {queryStatus === 'success' && isEmpty && <EmptyPlaceholder message="No components" />}
      {queryStatus === 'success' && !isEmpty && (
        <TableContainer
          isHeaderSticky
          columnTemplate="minmax(2rem, 4fr) minmax(2rem, 6fr) minmax(2rem, 4fr) 3fr "
          rowCount={components.length}
          role="table"
          tabIndex={0}
        >
          <Table variant="simple" highlightOnHover={false}>
            <Thead>
              <Tr role="row">
                {headerColumns.map(({ id, column, getContext }) => {
                  return (
                    <Th
                      role="columnheader"
                      tabIndex={-1}
                      key={id}
                      sorting={{
                        isActive: isSortingActive,
                        direction: column.getIsSorted(),
                        onChange: column.getToggleSortingHandler()
                      }}
                    >
                      {flexRender(column.columnDef.header, getContext())}
                    </Th>
                  );
                })}
                <Th>Actions</Th>
              </Tr>
            </Thead>

            <Tbody>
              {rows.map(row => {
                const isAssigned = Boolean(row.original.external_id);

                return (
                  <Tr role="row" key={row.id} w="100%" tabIndex={-1}>
                    {row.getVisibleCells().map(({ id, column, getContext }, index) => {
                      return (
                        <Td
                          key={id}
                          isFirstColumn={index === 0}
                          isNumeric={column.columnDef.meta?.isNumeric ?? false}
                          overflow="visible"
                          color={isAssigned ? 'inherit' : 'secondary.400'}
                        >
                          {flexRender(column.columnDef.cell, getContext())}
                        </Td>
                      );
                    })}
                    <ComponentActions row={row} isAssigned={isAssigned} portfolioId={portfolioId} plantViewId={plantId} />
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
