import { Flex } from '@chakra-ui/react';
import { Button } from '@hydrogrid/design-system';

interface EmptyPlaceholderProps {
  onClick?: (() => void) | undefined;
  message: string;
  buttonText?: React.ReactNode;
  isDisabled?: boolean;
}

export function EmptyPlaceholder({ onClick, message, buttonText, isDisabled = false }: EmptyPlaceholderProps) {
  const showButton = buttonText != null && onClick != null;

  return (
    <Flex display="flex" alignItems="center" justifyContent="center" flexDirection="column" h="100%" gap={4} height={30}>
      {message}
      {showButton && (
        <Button variant="outline" colorScheme="secondary" onClick={onClick} isDisabled={isDisabled}>
          {buttonText}
        </Button>
      )}
    </Flex>
  );
}
