import { Link } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { NavLink, type To } from 'react-router-dom';
import { Breadcrumb } from '../Breadcrumbs/Breadcrumb';

interface BreadcrumbLinkProps {
  to: To;
  children: ReactNode;
}

export function BreadcrumbLink({ to, children }: BreadcrumbLinkProps) {
  return (
    <Breadcrumb>
      <Link as={NavLink} to={to} _hover={{ textDecoration: 'none', opacity: '0.7' }} transition="opacity 0.2s">
        {children}
      </Link>
    </Breadcrumb>
  );
}
