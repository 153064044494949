import { createContext, type ReactNode, useContext, useEffect, useMemo, useRef } from 'react';

interface SavedCoordinate {
  update: (lat: number, lng: number) => void;
  remove(): void;
}

interface MapMarkersContextValue {
  add(lat: number, lng: number): SavedCoordinate;
}

const MapMarkersContext = createContext<MapMarkersContextValue | null>(null);

export function useProvideCoordinateToMap(lat: number, lng: number) {
  const context = useContext(MapMarkersContext);
  if (!context) {
    throw new Error('useProvideCoordinateToMap must be used inside a MapMarkersContext provider!');
  }

  const coordRef = useRef<SavedCoordinate>();

  // Add/update coordinate at map parent
  useEffect(() => {
    if (!coordRef.current) {
      coordRef.current = context.add(lat, lng);
    } else {
      coordRef.current.update(lat, lng);
    }
  }, [lat, lng, context]);

  // Remove saved coordinate from map parent when marker component is destroyed
  useEffect(() => {
    return () => {
      coordRef.current?.remove();
      coordRef.current = undefined;
    };
  }, []);
}

interface ProvideCoordinatesProps {
  children?: ReactNode;
  onUpdate: (coordinates: { lat: number; lng: number }[]) => void;
}

/** Used in the MapCanvas to keep track of MapMarker children. */
export function ProvideCoordinates({ children, onUpdate }: ProvideCoordinatesProps) {
  const notifyUpdatedCoordinates = useRef(onUpdate);
  notifyUpdatedCoordinates.current = onUpdate;

  const contextValue: MapMarkersContextValue = useMemo(() => {
    let list: { lat: number; lng: number }[] = [];

    return {
      add(lat: number, lng: number) {
        let coord = { lat, lng };
        list = [...list, coord];
        notifyUpdatedCoordinates.current(list);

        return {
          update: (lat: number, lng: number) => {
            const index = list.indexOf(coord);
            coord = { lat, lng };
            list = [...list.slice(0, index), coord, ...list.slice(index + 1, list.length - 1)];
            notifyUpdatedCoordinates.current(list);
          },
          remove: () => {
            list = list.filter(item => item !== coord);
            notifyUpdatedCoordinates.current(list);
          }
        };
      }
    };
  }, []);

  return <MapMarkersContext.Provider value={contextValue}>{children}</MapMarkersContext.Provider>;
}
