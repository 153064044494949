import { useRef, type PointerEvent as ReactPointerEvent } from 'react';

const preventSelectionWhileDrag = (event: PointerEvent) => {
  event.stopPropagation();
  event.preventDefault();
  return false;
};

/**
 * After how many pixels of mouse movement we want to actually start dragging.
 *
 * With this we avoid accidental "drag clicks".
 */
const DRAGGING_TOLERANCE_IN_PX = 5;

interface Props {
  onDrag?: (deltaX: number, deltaY: number) => void;
  onDragStart?: (event: PointerEvent) => void;
  onDragEnd?: (event: PointerEvent) => void;
}

/**
 * Hook for implementing basic Drag and Drop functionality
 */
export function useDrag({ onDrag, onDragStart, onDragEnd }: Props) {
  const startingPoint = useRef({ x: 0, y: 0 });
  const isDragging = useRef(false);

  function startDrag(event: ReactPointerEvent) {
    if (event.button === 2) {
      return;
    }

    function whileDrag(event: PointerEvent) {
      const deltaX = event.pageX - startingPoint.current.x;
      const deltaY = event.pageY - startingPoint.current.y;

      if (!isDragging.current) {
        if (Math.abs(deltaX) < DRAGGING_TOLERANCE_IN_PX && Math.abs(deltaY) < DRAGGING_TOLERANCE_IN_PX) {
          return;
        }
        onDragStart?.(event);
      }

      isDragging.current = true;
      onDrag?.(deltaX, deltaY);

      return preventSelectionWhileDrag(event);
    }

    function stopDrag(event: PointerEvent) {
      if (isDragging.current) {
        onDragEnd?.(event);
      }
      isDragging.current = false;
      window.removeEventListener('pointermove', whileDrag);
      window.removeEventListener('pointerup', stopDrag);
    }

    startingPoint.current = { x: event.pageX, y: event.pageY };
    isDragging.current = false;
    window.addEventListener('pointermove', whileDrag);
    window.addEventListener('pointerup', stopDrag);
  }

  return { startDrag, isDragging };
}
