import { useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext, type NavigateOptions, type To } from 'react-router-dom';

/* eslint-disable @typescript-eslint/unbound-method */

export type ShouldPreventCallback = (
  params:
    | {
        type: 'push' | 'replace';
        to: To;
        state: unknown;
        opts: NavigateOptions | undefined;
      }
    | { type: 'go'; delta: number; to?: never; state?: never; opts?: never }
) => boolean;

interface PendingNavigation {
  allow: () => void;
  deny: () => void;
}

/**
 * Utility hook that allow you to prevent navigation in an asynchronous way.
 *
 * @usage
 * ```tsx
 * const pendingNavigation = usePreventNavigation(condition === true);
 *
 * return <>
 *   <YourComponent />
 *   {pendingNavigation != null && <ModalOrPopover>
 *     <Button onClick={pendingNavigation.allow}>Navigate away</Button>
 *     <Button onClick={pendingNavigation.deny}>Stay on the page</Button>
 *   </ModalOrPopover>}
 * </>;
 * ```
 */
export function usePreventNavigation(shouldPrevent?: boolean | ShouldPreventCallback): PendingNavigation | undefined {
  const { navigator } = useContext(NavigationContext);
  const [pendingNavigation, setPendingNavigation] = useState<PendingNavigation>();

  const shouldPreventRef = useRef(shouldPrevent);
  shouldPreventRef.current = shouldPrevent;

  useEffect(() => {
    if (shouldPrevent === false) return;

    const originalGo = navigator.go;
    const originalPush = navigator.push;
    const originalReplace = navigator.replace;

    navigator.go = delta => {
      const shouldPrevent = shouldPreventRef.current;
      if (typeof shouldPrevent === 'function' && !shouldPrevent({ type: 'go', delta })) {
        return originalGo.call(navigator, delta);
      }

      setPendingNavigation({
        allow: () => {
          setPendingNavigation(undefined);
          originalGo.call(navigator, delta);
        },
        deny: () => setPendingNavigation(undefined)
      });
    };

    navigator.push = (to, state, opts) => {
      const shouldPrevent = shouldPreventRef.current;
      if (typeof shouldPrevent === 'function' && !shouldPrevent({ to, state: state as unknown, opts, type: 'push' })) {
        return originalPush.call(navigator, to, state, opts);
      }

      setPendingNavigation({
        allow: () => {
          setPendingNavigation(undefined);
          originalPush(to, state, opts);
        },
        deny: () => setPendingNavigation(undefined)
      });
    };

    navigator.replace = (to, state, opts) => {
      const shouldPrevent = shouldPreventRef.current;
      if (typeof shouldPrevent === 'function' && !shouldPrevent({ to, state: state as unknown, opts, type: 'replace' })) {
        return originalReplace.call(navigator, to, state, opts);
      }
      setPendingNavigation({
        allow: () => {
          setPendingNavigation(undefined);
          originalReplace(to, state, opts);
        },
        deny: () => setPendingNavigation(undefined)
      });
    };

    return () => {
      navigator.go = originalGo;
      navigator.push = originalPush;
      navigator.replace = originalReplace;
    };
  }, [navigator, shouldPrevent]);

  return pendingNavigation;
}
