import { Box, Flex } from '@chakra-ui/react';
import { Children as ChildrenUtils, type ComponentProps, type ReactElement } from 'react';
import { ScrollPanel } from '../ScrollPanel/ScrollPanel';
import type { Breadcrumb } from './Breadcrumb';
import ChevronRight from './chevron-right.svg?react';

type RenderedBreadcrumb = ReactElement<ComponentProps<typeof Breadcrumb>, typeof Breadcrumb>;

interface BreadcrumbsProps {
  children?: (ReactElement | string) | (ReactElement | boolean | string | null | undefined)[];

  /** `true` to wrap children in a <span>. */
  wrapChildren?: boolean;
}

function isRenderedBreadcrumb(child: unknown): child is RenderedBreadcrumb {
  return (
    typeof child === 'object' &&
    child != null &&
    typeof (child as RenderedBreadcrumb).type === 'function' &&
    (child as RenderedBreadcrumb).type.isBreadcrumb === true
  );
}

export function Breadcrumbs({ children, wrapChildren = false }: BreadcrumbsProps) {
  const breadcrumbs = ChildrenUtils.toArray(children).filter((child: unknown) => child !== false && child != null);

  return (
    <ScrollPanel axis="horizontal" fitContent width="100%">
      <Flex wrap="nowrap" align="center">
        {breadcrumbs.map((child, index) => {
          return (
            <Flex key={index}>
              {index > 0 && (
                <Flex align="center" px={3}>
                  <ChevronRight fill="currentColor" height="1em" />
                </Flex>
              )}
              {wrapChildren && !isRenderedBreadcrumb(child) ? <Box>{child}</Box> : child}
            </Flex>
          );
        })}
      </Flex>
    </ScrollPanel>
  );
}
