import { keyCombination } from '@hydrogrid/design-system';
import type { KeyboardEvent } from 'react';

/**
 * - Up/Down: selects next/previous item in list
 * - Space: Check/uncheck item
 * - Enter: Select item
 * @example
 * <div role="listbox">
 *  <div tabIndex={0}></div> //or any tabIndex that isn't -1
 *  ...
 * </div>
 */
export function handleListKeyboardNavigation(event: KeyboardEvent<HTMLDivElement>) {
  if (event.defaultPrevented) return;

  const key = keyCombination(event);

  const row = event.target as HTMLElement;
  const list = row.closest<HTMLElement>('[role="listbox"]');
  if (!list) return;

  const allRows = [...list.querySelectorAll<HTMLElement>('[tabindex]:not([tabindex="-1"])')];

  let index = allRows.indexOf(row);
  if (index < 0) {
    index = allRows.findIndex(sibling => sibling.contains(row));
  }

  const checkbox = row.querySelector<HTMLInputElement>('input[type="checkbox"]');

  if (key === 'Enter') {
    row.click();
    event.preventDefault();
  } else if (key === 'Space' && checkbox) {
    event.preventDefault();
    checkbox.click();
  } else if (key === 'ArrowUp') {
    event.preventDefault();
    allRows[index - 1]?.focus();
  } else if (key === 'ArrowDown') {
    event.preventDefault();
    allRows[index + 1]?.focus();
  }
}
