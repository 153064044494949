import { Box, Th as ChakraTh, Flex, Icon, type TableColumnHeaderProps } from '@chakra-ui/react';
import { ArrowDownCircleIcon } from '../../icons';
import { useTableContext } from './TableContext';
import { useTableStore } from './TableStore';

export interface ThProps extends TableColumnHeaderProps {
  isFirstColumn?: boolean;
  sorting?:
    | {
        isActive: boolean;
        direction: false | 'asc' | 'desc';
        onChange: undefined | ((event: unknown) => void);
      }
    | undefined;
}

export function Th({ isFirstColumn = false, sorting, children, ...props }: ThProps) {
  const showShadowLeft = useTableStore(state => state.activeShadows.left);
  const { isFirstColumnSticky } = useTableContext();
  const isSticky = isFirstColumn && isFirstColumnSticky;

  const isSortable = sorting !== undefined;
  const sortingIsActive = sorting?.isActive ?? false;
  const sortingDirection = sorting?.direction ?? false;
  const showSortingIcon = sortingIsActive && sortingDirection !== false;

  return (
    <ChakraTh
      {...(isSticky
        ? {
            pos: 'sticky',
            left: 0,
            bg: 'white',
            zIndex: 1,
            boxShadow: showShadowLeft ? '1px 0px 0px var(--chakra-colors-secondary-200)' : (undefined as never)
          }
        : {})}
      {...(isSortable
        ? {
            onClick: sorting.onChange,
            cursor: 'pointer',
            userSelect: 'none'
          }
        : {})}
      {...props}
    >
      <Flex
        align="center"
        flexDir={Boolean(props.isNumeric) ? 'row-reverse' : 'row'}
        justify={Boolean(props.isNumeric) && isFirstColumn ? 'flex-end' : 'space-between'}
        gap={2}
        opacity={!sortingIsActive || showSortingIcon ? (undefined as never) : 0.7}
      >
        <Box isTruncated>{children}</Box>
        {isSortable && (
          <>
            {showSortingIcon ? (
              <Icon as={ArrowDownCircleIcon} transform={sortingDirection === 'asc' ? 'rotate(180deg)' : (undefined as never)} />
            ) : (
              <Box boxSize={4} />
            )}
          </>
        )}
      </Flex>
    </ChakraTh>
  );
}
