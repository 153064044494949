import { Flex } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormFooter, FormInput, useFormChangesDialog } from '@hydrogrid/design-system';
import { useForm, type FieldPath } from 'react-hook-form';
import { z } from 'zod';
import { useUpdatePlantIdOrName } from '../../../../common/api/hooks/internalDataHooks';
import { routes } from '../../../../common/routing/routes';
import { useAppNavigate } from '../../../../common/routing/useAppNavigate';
import { useAppRoute } from '../../../../common/routing/useAppRoute';

const formSchema = z.object({
  name: z.string().optional(),
  external_id: z.string().optional()
});

type FormSchema = z.infer<typeof formSchema>;

const formFieldLabels: Record<FieldPath<FormSchema>, string> = {
  name: 'Display Name of Plant View',
  external_id: 'External ID of Plant View'
};

interface Props {
  name: string;
  external_id: string;
}

export default function PlantViewForm({ name, external_id }: Props) {
  const route = useAppRoute(routes.managePlantView);

  const { portfolioId, plantId } = route.params;
  const appNavigate = useAppNavigate();

  const form = useForm<FormSchema>({
    mode: 'onBlur',
    defaultValues: {
      name,
      external_id
    },
    resolver: zodResolver(formSchema)
  });

  const { dirtyFields, defaultValues: defaultFormValues } = form.formState;
  const formValues = form.watch();
  const formChangesDialog = useFormChangesDialog({
    formValues,
    dirtyFields,
    defaultValues: defaultFormValues,
    formFieldLabels
  });

  const handleBeforeSubmit = async () => {
    if (await formChangesDialog()) {
      return true;
    }
    return false;
  };

  const plantNameOrIdMutation = useUpdatePlantIdOrName(portfolioId, external_id);
  const handleSubmit = async (data: FormSchema) => {
    return new Promise<boolean>(resolve => {
      plantNameOrIdMutation.mutate(
        {
          portfolioId,
          plantViewId: plantId ?? '',
          body: { name: data.name, id: data.external_id }
        },
        {
          onSuccess: () => {
            resolve(true);
            if (data.external_id) {
              appNavigate(routes.managePlantView, { params: { ...route.params, plantId: data.external_id } }, { replace: true });
            }
          },
          onError: () => resolve(false)
        }
      );
    });
  };
  return (
    <Form form={form} onValidSubmit={handleSubmit} onBeforeSubmit={handleBeforeSubmit} py="2" unsavedChangesDialog={false}>
      <Flex flexDir={['column', null, 'row']} gap="4">
        <FormInput control={form.control} name="name" fieldName="name" label={formFieldLabels.name} placeholder="Plant Name" />
        <FormInput
          control={form.control}
          name="external_id"
          fieldName="external_id"
          label={formFieldLabels.external_id}
          placeholder="HDG_EXT"
        />
      </Flex>

      <FormFooter />
    </Form>
  );
}
