import { Flex, Text } from '@chakra-ui/react';
import { type ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { TimeZoneIcon } from '../../icons';
import { Tag } from '../Tag/Tag';
import { Tooltip } from '../Tooltip/Tooltip';
import { usePageNavigationHost } from './PageNavigationContext';

interface PageNavigationProps {
  children: ReactNode;
  timeZone?: string;
}

/**
 * Renders a page navigation in the parent `PageNavigationHost`.
 * Allows splitting layout (PageNavigationHost) and page (PageNavigation).
 *
 * @example
 * ```tsx
 * <ProvidePageNavigationContext>
 *   <header>
 *     <Icon />
 *     <PageNavigationHost as="ul" className="my-nav" />
 *   </header>
 *   <Page>
 *     <PageNavigation>{'A > B > C'}</PageNavigation>
 *   </Page>
 * </ProvidePageNavigationContext>
 * ```
 * */
export function PageNavigation({ children, timeZone }: PageNavigationProps) {
  const host = usePageNavigationHost();
  return (
    host &&
    createPortal(
      <Flex width="100%" alignItems="center" justify="space-between" overflow="hidden">
        {children}
        {timeZone && (
          <Tag p="3" variant="outline" colorScheme="secondary" display={['none', 'flex']}>
            <Tooltip label="You are seeing data in this timezone" fontSize="xs">
              <Flex alignItems="center" gap="1">
                <TimeZoneIcon color="primary" />
                <Text>{timeZone}</Text>
              </Flex>
            </Tooltip>
          </Tag>
        )}
      </Flex>,
      host
    )
  );
}
