import { Box, type FlexProps } from '@chakra-ui/react';
import { FormProvider, type FieldValues, type UseFormReturn } from 'react-hook-form';
import type { ShouldPreventCallback } from '../../../routing/usePreventNavigation';
import { usePreventUnsavedChanges } from './usePreventUnsavedChanges';

interface FormProps<T extends FieldValues> extends Omit<FlexProps, 'onSubmit'> {
  form: UseFormReturn<T>;
  onBeforeSubmit?: (data: T) => Promise<boolean> | boolean;

  /**
   * This is only being called when all react hook form validations are successful.
   */
  onValidSubmit: (data: T) => Promise<boolean>;

  /**
   * This is being called after onValidSubmit has been resolved succesfully.
   *
   * It is useful for navigating away from the form after the form has been resetted succesfully.
   */
  onSuccessfulSubmit?: () => void;

  preventNavigationCheck?: ShouldPreventCallback;
  checkOnNavigate?: boolean;

  /**
   * To determine if the form will have a unsaved changes dialog
   */
  unsavedChangesDialog?: boolean;
  /**
   * This callback function is called when user leaves the form
   * and after the preventUnsavedChanges dialog appears
   */
  onBeforeLeave?: () => void;
}

export function Form<T extends FieldValues>(formProps: FormProps<T>) {
  const {
    form,
    onValidSubmit,
    onSuccessfulSubmit,
    onBeforeSubmit,
    children,
    checkOnNavigate = true,
    preventNavigationCheck,
    onBeforeLeave,
    unsavedChangesDialog = true,
    ...props
  } = formProps;
  const isDirty = Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <>
      <FormProvider {...form}>
        <Box
          as="form"
          noValidate
          onSubmit={e => {
            void form.handleSubmit(async data => {
              if (onBeforeSubmit) {
                const beforeSubmitResult = await onBeforeSubmit(data);
                if (!beforeSubmitResult) {
                  return;
                }
              }

              const result = await onValidSubmit(data);
              const isSuccessful = typeof result !== 'boolean' || result === true;
              if (isSuccessful) {
                form.reset(data);

                // Dev-Note: we need this setTimeout to make sure that the form has been resetted successfully
                setTimeout(() => {
                  onSuccessfulSubmit?.();
                }, 10);
              }

              return result;
            })(e);
          }}
          {...props}
        >
          {children}
        </Box>
      </FormProvider>

      {unsavedChangesDialog && (
        <PreventUnsavedChanges
          isDirty={isDirty}
          preventNavigationCheck={preventNavigationCheck}
          checkOnNavigate={checkOnNavigate}
          onBeforeLeave={onBeforeLeave}
        />
      )}
    </>
  );
}

interface PreventUnsavedChangesProps {
  preventNavigationCheck: ShouldPreventCallback | undefined;
  checkOnNavigate: boolean;
  onBeforeLeave: (() => void) | undefined;
  isDirty: boolean;
}

function PreventUnsavedChanges({ checkOnNavigate = true, preventNavigationCheck, onBeforeLeave, isDirty }: PreventUnsavedChangesProps) {
  usePreventUnsavedChanges(
    checkOnNavigate ? isDirty : false,
    params => {
      if (typeof preventNavigationCheck === 'function') {
        return preventNavigationCheck(params);
      }
      return isDirty;
    },
    onBeforeLeave
  );

  return null;
}
