import { useClickOutside } from '@hydrogrid/design-system';
import { useCallback, useState, type MouseEvent, type ReactNode } from 'react';
import styles from './MapLegend.module.css';

interface MapLegendProps {
  children?: ReactNode;
}

export function MapLegend({ children }: MapLegendProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useClickOutside({
    enabled: isOpen,
    ref: container,
    handler: () => setIsOpen(false)
  });

  return (
    <div className={styles.mapLegendContainer} ref={setContainer}>
      {isOpen && <div className={styles.mapLegend}>{children}</div>}
      <ToggleButton isOpen={isOpen} onToggle={setIsOpen} />
    </div>
  );
}

interface ToggleButtonProps {
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
}

function ToggleButton({ isOpen, onToggle }: ToggleButtonProps) {
  const toggleOnButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (event.defaultPrevented) return;
      onToggle(!isOpen);
    },
    [isOpen, onToggle]
  );

  return (
    <button
      type="button"
      name="Toggle map legend"
      className={styles.button}
      onClick={toggleOnButtonClick}
      aria-label={isOpen ? 'Hide map legend' : 'Show map legend'}
      aria-haspopup={true}
      aria-expanded={isOpen}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" className={isOpen ? styles.closeIcon : styles.infoIcon} data-testid="legend-icon">
        {/* Circle */}
        <path className={styles.buttonCircle} d="M12 .77a11.23 11.23 0 1 0 0 22.46A11.23 11.23 0 0 0 12 .77Z" />
        <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24Zm0 22.52A10.51 10.51 0 1 1 22.5 12c0 5.79-4.71 10.52-10.5 10.52Z" />

        {/* Cross */}
        <path
          opacity={isOpen ? 1 : 0}
          aria-hidden={!isOpen}
          d="M15.9 6.8a.92.92 0 1 1 1.3 1.3L13.3 12l3.9 3.9a.92.92 0 1 1-1.3 1.3L12 13.3l-3.9 3.9a.92.92 0 0 1-1.3-1.3l3.9-3.9-3.9-3.9a.92.92 0 1 1 1.3-1.3l3.9 3.9 3.9-3.9Z"
        />

        {/* Questionmark */}
        <path
          opacity={isOpen ? 0 : 1}
          aria-hidden={isOpen}
          d="M16.02 8.35a4.1 4.1 0 0 0-6.65-2.4 4.07 4.07 0 0 0-1.45 3.12 1.02 1.02 0 1 0 2.04 0c0-.6.27-1.17.72-1.56a2.04 2.04 0 0 1 3.3 1.19A2.04 2.04 0 0 1 12.63 11a2.4 2.4 0 0 0-1.65 2.33v1.31a1.02 1.02 0 1 0 2.04 0v-1.3c0-.19.1-.34.24-.38a4.05 4.05 0 0 0 2.77-4.62ZM12 16.42a1.3 1.3 0 1 0 0 2.59 1.3 1.3 0 0 0 0-2.59Z"
        />
      </svg>
    </button>
  );
}
