import { defineMarkerComponent } from './defineMarkerComponent';

import PlantMarkerSvg from './plantPrimary.svg?react';
import ReservoirMarkerSvg from './reservoirPrimary.svg?react';

import downstreamPrimaryUrl from './downstreamPrimary.svg?url';
import downstreamSecondaryUrl from './downstreamSecondary.svg?url';
import gatePrimaryUrl from './gatePrimary.svg?url';
import gateSecondaryUrl from './gateSecondary.svg?url';
import plantCautionUrl from './plantCaution.svg?url';
import plantInfoUrl from './plantInfo.svg?url';
import plantPrimaryUrl from './plantPrimary.svg?url';
import plantSecondaryUrl from './plantSecondary.svg?url';
import plantShadowUrl from './plantShadow.svg?url';
import plantWarningUrl from './plantWarning.svg?url';
import questionUrl from './question.svg?url';
import reservoirPrimaryUrl from './reservoirPrimary.svg?url';
import reservoirSecondaryUrl from './reservoirSecondary.svg?url';
import turbinePrimaryUrl from './turbinePrimary.svg?url';
import turbineSecondaryUrl from './turbineSecondary.svg?url';

const markersByName = {
  plantPrimary: plantPrimaryUrl,
  plantSecondary: plantSecondaryUrl,
  plantInfo: plantInfoUrl,
  plantWarning: plantWarningUrl,
  plantCaution: plantCautionUrl,
  plantShadow: plantShadowUrl,
  question: questionUrl,
  downstreamPrimary: downstreamPrimaryUrl,
  downstreamSecondary: downstreamSecondaryUrl,
  gatePrimary: gatePrimaryUrl,
  gateSecondary: gateSecondaryUrl,
  reservoirPrimary: reservoirPrimaryUrl,
  reservoirSecondary: reservoirSecondaryUrl,
  turbinePrimary: turbinePrimaryUrl,
  turbineSecondary: turbineSecondaryUrl
};

export type MapMarkerName = keyof typeof markersByName;

export function getMarkerUrl(name: MapMarkerName) {
  return markersByName[name];
}

export const PlantMarker = defineMarkerComponent('PlantMarker', PlantMarkerSvg);
export const ReservoirMarker = defineMarkerComponent('ReservoidMarker', ReservoirMarkerSvg);
