import { Thead as ChakraThead, type TableHeadProps as ChakraTableHeadProps } from '@chakra-ui/react';
import { useTableContext } from './TableContext';
import { useTableStore } from './TableStore';

export interface TheadProps extends ChakraTableHeadProps {}

export function Thead(props: TheadProps) {
  const { isHeaderSticky } = useTableContext();
  const showShadowTop = useTableStore(state => state.activeShadows.top);

  return (
    <ChakraThead
      display="grid"
      {...(isHeaderSticky
        ? {
            pos: 'sticky',
            top: '0',
            bg: 'white',
            zIndex: 2,
            boxShadow: showShadowTop
              ? '0px 1px 0px var(--chakra-colors-secondary-100), 0px 1px 10px var(--chakra-colors-secondary-400)'
              : (undefined as never)
          }
        : {})}
      {...props}
    />
  );
}
