import type { DataRange } from '@hydrogrid/design-system';
import { createTaskPool } from '../../utils/createTaskPool';
import { api } from '../InsightApi';
import type { TimeZone as BackendTimeZone } from '../generated-client/Schemas';
import { dataRangeToBackendParameters } from '../query-utils/dataRangeToBackendParameters';
import { defineQuery } from '../query-utils/defineQuery';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const staleTime = 4 * HOUR;

const reportingQueryPool = createTaskPool({
  concurrency: 10
});

export const generationOfPlant = defineQuery(
  ({
    portfolioId,
    plantId,
    range,
    timeZone,
    enabled = true
  }: {
    portfolioId: string;
    plantId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
    enabled?: boolean;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'generation', timeZone, range],
    queryFn: async ({ signal }) => {
      return await reportingQueryPool.add(() =>
        api.dashboard.plant.requestPower({
          portfolio_id: portfolioId,
          plant_id: plantId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
      );
    },
    staleTime,
    enabled
  })
);

export const generatedEnergyOfPlantAndTurbines = defineQuery(
  ({
    portfolioId,
    plantId,
    range,
    timeZone,
    enabled = true
  }: {
    portfolioId: string;
    plantId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
    enabled?: boolean;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'energy', timeZone, range],
    queryFn: async ({ signal }) => {
      return await reportingQueryPool.add(() =>
        api.dashboard.plant.requestEnergy({
          portfolio_id: portfolioId,
          plant_id: plantId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
      );
    },
    staleTime,
    enabled
  })
);

export const waterContentOfPlant = defineQuery(
  ({
    portfolioId,
    plantId,
    range,
    timeZone,
    enabled = true
  }: {
    portfolioId: string;
    plantId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
    enabled?: boolean;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'waterContent', timeZone, range],
    queryFn: async ({ signal }) => {
      return await reportingQueryPool.add(() =>
        api.dashboard.plant.requestWaterContent({
          portfolio_id: portfolioId,
          plant_id: plantId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
      );
    },
    staleTime,
    enabled
  })
);

export const inflowOfPlant = defineQuery(
  ({
    portfolioId,
    plantId,
    range,
    timeZone,
    enabled = true
  }: {
    portfolioId: string;
    plantId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
    enabled?: boolean;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'inflow', timeZone, range],
    queryFn: async ({ signal }) => {
      const query = await reportingQueryPool.add(() => {
        const backendParams = dataRangeToBackendParameters(range, timeZone);
        const { granularity, ...newBackendParams } = backendParams;
        const queryParams = {
          ...newBackendParams,
          ...(granularity !== 'half-hour' && granularity !== undefined ? { granularity } : {})
        };
        return api.dashboard.plant.getInflowActualAndForecast({
          portfolio_id: portfolioId,
          plant_id: plantId,
          queryParams,
          signal
        });
      });
      if (
        typeof query.data !== 'object' ||
        query.data === null ||
        !('reservoirs' in query.data) ||
        !('overall' in query.data) ||
        !('aggregated_timeseries' in query.data)
      ) {
        throw new Error('getInflowActualAndForecast response did not match the type');
      }
      return query;
    },
    staleTime,
    enabled
  })
);

export const marketPriceForPlant = defineQuery(
  ({
    portfolioId,
    plantId,
    range,
    timeZone,
    enabled = true
  }: {
    portfolioId: string;
    plantId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
    enabled?: boolean;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'marketPrice', timeZone, range],
    queryFn: async ({ signal }) => {
      return await reportingQueryPool.add(() =>
        api.dashboard.plant.getDayAheadPrice({
          portfolio_id: portfolioId,
          plant_id: plantId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
      );
    },
    staleTime,
    enabled
  })
);

export const realizedPriceForPlant = defineQuery(
  ({
    portfolioId,
    plantId,
    range,
    timeZone,
    enabled = true
  }: {
    portfolioId: string;
    plantId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
    enabled?: boolean;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'realizedPrice', timeZone, range],
    queryFn: async ({ signal }) => {
      return await reportingQueryPool.add(() => {
        const backendParams = dataRangeToBackendParameters(range, timeZone);
        const { granularity, ...newBackendParams } = backendParams;
        const queryParams = {
          ...newBackendParams,
          ...(granularity !== 'half-hour' && granularity !== undefined ? { granularity } : {})
        };
        return api.dashboard.plant.getRealizedDayAheadPriceAndForecast({
          portfolio_id: portfolioId,
          plant_id: plantId,
          queryParams,
          signal
        });
      });
    },
    staleTime,
    enabled
  })
);

export const revenueForPlant = defineQuery(
  ({
    portfolioId,
    plantId,
    range,
    timeZone,
    enabled = true
  }: {
    portfolioId: string;
    plantId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
    enabled?: boolean;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'revenue', timeZone, range],
    queryFn: async ({ signal }) => {
      return await reportingQueryPool.add(() =>
        api.dashboard.plant.getRevenueAndForecast({
          portfolio_id: portfolioId,
          plant_id: plantId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
      );
    },
    staleTime,
    enabled
  })
);

export const inflowWeightedPriceForPlant = defineQuery(
  ({
    portfolioId,
    plantId,
    range,
    timeZone,
    enabled = true
  }: {
    portfolioId: string;
    plantId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
    enabled?: boolean;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'inflowWeightedPrice', timeZone, range],
    queryFn: async ({ signal }) => {
      return await reportingQueryPool.add(() =>
        api.dashboard.plant.requestInflowWeightedDayAheadPrice({
          portfolio_id: portfolioId,
          plant_id: plantId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
      );
    },
    staleTime,
    enabled
  })
);
