import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppActions } from '../../common/accessibility/useAppActions';
import * as queries from '../../common/api/queries';
import type { AnyTypedRoute, PartialReactRouterLinkProps, UnknownTypedRoute } from '../../common/routing/TypedRoutes';
import { useAppNavigate } from '../../common/routing/useAppNavigate';
import { useAppRoute } from '../../common/routing/useAppRoute';
import { lastViewedPortfolioKey } from '../../common/storage/storageKeys';
import { useSessionStorageState } from '../../common/storage/useSessionStorage';
import { useEffectOnce } from '../../common/utils/useEffectOnce';
import { DropdownNavigationBreadcrumb } from './DropdownNavigationBreadcrumb';

interface PortfolioBreadcrumbProps {
  /**
   * `true` to make the breadcrumb and items in the dropdown links to the current page.
   * @default true
   */
  createLinks?: boolean;

  /**
   * When set to `true`, selecting a portfolio will replace the current navigation entry.
   *
   * If you pass a `createLink` callback, you can just return `{ replace: true }`.
   */
  replaceOnSelect?: boolean;

  /**
   * Called when the user selects a portfolio from the dropdown.
   * Return `false` from the passed function to cancel navigating to that portfolio.
   */
  onChange?: (newPortfolioId: string) => boolean | void;

  /**
   * Factory for links to navigate when selecting a portfolio, items in the list & the current item in the breadcrumb.
   * In most cases, you want to call `someTypedRoute.link()` with the appropriate arguments.
   */
  createLink?: (newPortfolioId: string) => PartialReactRouterLinkProps;

  /**
   * Route to navigate to when selecting a portfolio, and for which link are created.
   * Use {@link createLink} for more complex scenarios, e.g. when you want to keep/change some query parameters.
   */
  targetRoute?: AnyTypedRoute;

  /** @default false */
  disabled?: boolean;

  /**
   * It defines if the breadcrumb can act as a link or not
   *
   * @default false
   */
  renderAsLink?: boolean;
}

export function PortfolioBreadcrumb({
  createLinks = true,
  replaceOnSelect,
  disabled = false,
  targetRoute: targetRouteFromProps,
  createLink,
  onChange,
  renderAsLink = false
}: PortfolioBreadcrumbProps) {
  const currentRoute = useAppRoute('*');
  const currentPortfolioId = (currentRoute.params as { portfolioId: string }).portfolioId;
  const targetRoute: UnknownTypedRoute = targetRouteFromProps ?? currentRoute.matchedRoute;

  const appNavigate = useAppNavigate();
  const routerNavigate = useNavigate();

  const [_lastViewedPortfolioId, saveLastViewedPortfolioId] = useSessionStorageState(
    undefined,
    lastViewedPortfolioKey,
    (parsed): parsed is string => typeof parsed === 'string'
  );

  useEffectOnce(() => {
    if (import.meta.env.PROD) return;

    if (!currentRoute.matchedRoute.pathParamNames.includes('portfolioId') || currentPortfolioId === undefined) {
      throw new Error('You used PortfolioBreadcrumb on a route which does not have a portfolioId parameter.');
    }

    if (createLink) return;

    const params = targetRoute.pathParams;
    if (params.slice(params.findIndex(param => param.name === 'portfolioId') + 1).some(param => !param.optional)) {
      throw new Error(
        'You used PortfolioBreadcrumb for a route which has non-optional parameters after the portfolioId. Provide the createLink property.'
      );
    }
  });

  const portfolioListQuery = useQuery(queries.portfolioList());
  const portfolios = useMemo(() => portfolioListQuery.data ?? [], [portfolioListQuery]);

  const otherParams = useMemo(() => {
    const otherParams: Record<string, unknown> = {};
    for (const { name, optional } of targetRoute.pathParams) {
      if (optional || name === 'portfolioId') break;
      otherParams[name] = currentRoute.params[name];
    }

    return otherParams;
  }, [currentRoute, targetRoute]);

  const selectPortfolio = useCallback(
    (portfolioId: string) => {
      if (onChange?.(portfolioId) === false) return;

      saveLastViewedPortfolioId(portfolioId);

      if (createLink) {
        const link = createLink(portfolioId);
        return routerNavigate(link.to, {
          state: link.state as unknown,
          replace: replaceOnSelect ?? link.replace
        });
      }

      appNavigate(
        targetRoute,
        { params: { ...otherParams, portfolioId } },
        replaceOnSelect != null ? { replace: replaceOnSelect } : undefined
      );
    },
    [onChange, saveLastViewedPortfolioId, createLink, appNavigate, targetRoute, otherParams, replaceOnSelect, routerNavigate]
  );

  const linkToPortfolioWithId = useCallback(
    (portfolioId: string) => {
      if (createLink) {
        return createLink(portfolioId);
      }

      try {
        return targetRoute.link({
          params: { ...otherParams, portfolioId }
        });
      } catch {
        // TODO(leon): Don't. This should solve with the "Breadcrumbs in Portal" ticket.
        return {} as unknown as PartialReactRouterLinkProps;
      }
    },
    [otherParams, targetRoute, createLink]
  );

  useAppActions([
    {
      id: 'previous-portfolio',
      name: 'Previous Portfolio',
      group: 'Portfolios',
      hotkey: 'Shift+O',
      callback: () => {
        const prevPortfolio = portfolios.find((_, index) => portfolios[index + 1]?.id === currentPortfolioId);
        if (prevPortfolio) {
          selectPortfolio(prevPortfolio.id);
        }
      },
      enabled: portfolios.length > 1
    },
    {
      id: 'next-portfolio',
      name: 'Next Portfolio',
      group: 'Portfolios',
      hotkey: 'O',
      callback: () => {
        const nextPortfolio = portfolios.find((_, index) => portfolios[index - 1]?.id === currentPortfolioId);
        if (nextPortfolio) {
          selectPortfolio(nextPortfolio.id);
        }
      },
      enabled: portfolios.length > 1
    }
  ]);

  return (
    <DropdownNavigationBreadcrumb
      resource={portfolioListQuery}
      currentId={currentPortfolioId}
      onChange={selectPortfolio}
      id="portfolio-breadcrumb"
      label="Select a portfolio"
      disabled={disabled}
      loadingFailedMessage="Portfolios can not be loaded."
      noItemsMessage="No portfolios"
      linkToId={linkToPortfolioWithId}
      linkCurrent={createLinks}
      linksInList={createLinks}
      renderAsLink={renderAsLink}
    />
  );
}
