import { Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  FormInput,
  MinusIcon,
  PencilIcon,
  PlusIcon,
  Portal,
  SaveIcon,
  Td,
  useFormChangesDialog
} from '@hydrogrid/design-system';
import { stringToCapitalize } from '@hydrogrid/utilities/string/stringCase';
import type { Row } from '@tanstack/react-table';
import { useForm, type FieldPath } from 'react-hook-form';
import { z } from 'zod';
import { Dialog } from '../../../../../design-system/library/components/Dialog/Dialog';
import type { InExTernalComponent } from '../../../../common/api/generated-client/Schemas';
import { usePlantView } from '../../../../common/api/hooks/CommonDataHooks';
import { useAssignComponent, useRemoveComponent, useUpdateComponent } from '../../../../common/api/hooks/internalDataHooks';

interface ComponentActionsProps {
  row: Row<InExTernalComponent>;
  isAssigned: boolean;
  portfolioId: string;
  plantViewId: string;
}

export function ComponentActions({ row, isAssigned, portfolioId, plantViewId }: ComponentActionsProps) {
  const plantView = usePlantView(portfolioId, plantViewId);
  const { internal_id: id, type, name } = row.original;

  const componentType = stringToCapitalize(type);

  const unassignComponentDisclosure = useDisclosure();
  const assignComponentDisclosure = useDisclosure();
  const editComponentDisclosure = useDisclosure();

  return (
    <>
      <Td>
        <Flex gap={2.5}>
          {isAssigned && (
            <IconButton
              icon={<MinusIcon />}
              minW={0}
              w={5.5}
              h={5.5}
              _hover={{ cursor: 'pointer', opacity: '0.5' }}
              variant="link"
              colorScheme="error"
              aria-label="unassign component from plant view"
              data-testid={`unassign component from plant view ${id}`}
              onClick={unassignComponentDisclosure.onOpen}
            />
          )}
          {!isAssigned && (
            <IconButton
              icon={<PlusIcon />}
              minW={0}
              w={5.5}
              h={5.5}
              _hover={{ cursor: 'pointer', opacity: '0.7' }}
              variant="link"
              colorScheme="green"
              aria-label="assign component to plant view"
              data-testid={`assign component from plant view ${id}`}
              onClick={assignComponentDisclosure.onOpen}
            />
          )}
          <IconButton
            icon={<PencilIcon />}
            minW={0}
            w={5.5}
            h={5.5}
            _hover={{ cursor: 'pointer', opacity: '0.7' }}
            variant="link"
            colorScheme="secondary"
            aria-label="edit component"
            onClick={editComponentDisclosure.onOpen}
          />
        </Flex>
      </Td>
      <Portal>
        {unassignComponentDisclosure.isOpen && (
          <UnassignComponentDialog
            onClose={unassignComponentDisclosure.onClose}
            portfolioId={portfolioId}
            plantViewId={plantViewId}
            originalName={name}
            plantName={plantView.data?.name ?? ''}
            component_id={id}
            component_type={componentType}
          />
        )}
        {assignComponentDisclosure.isOpen && (
          <AssignComponentDialog
            onClose={assignComponentDisclosure.onClose}
            portfolioId={portfolioId}
            plantViewId={plantViewId}
            originalName={name}
            plantName={plantView.data?.name ?? ''}
            component_id={id}
            component_type={componentType}
          />
        )}
        {editComponentDisclosure.isOpen && (
          <EditComponentDialog
            onClose={editComponentDisclosure.onClose}
            portfolioId={portfolioId}
            plantViewId={plantViewId}
            component_id={id}
            component_type={componentType}
            originalName={name}
          />
        )}
      </Portal>
    </>
  );
}

interface ComponentDialogProps {
  onClose: () => void;
  portfolioId: string;
  plantViewId: string;
  plantName?: string;
  originalName: string;
  component_type: string;
  component_id: string;
}

function UnassignComponentDialog({
  onClose,
  portfolioId,
  plantViewId,
  plantName,
  originalName,
  component_type,
  component_id
}: ComponentDialogProps) {
  const removeComponent = useRemoveComponent(portfolioId, plantViewId);
  const onUnassign = async () => {
    return await new Promise<boolean>(resolve => {
      removeComponent.mutate(
        { portfolioId, plantViewId, component_type, component_id },
        {
          onSuccess: () => {
            onClose();
            resolve(true);
          },
          onError: () => resolve(false)
        }
      );
    });
  };

  return (
    <Dialog title={`Unassign ${originalName} from ${plantName}`} isOpen={true} onClose={onClose}>
      <Text fontSize="md">{`Are you sure you want to unassign the component ${originalName} from the plant ${plantName}?`}</Text>

      <Flex justify="flex-end" gap={3} mt="6">
        <Button onClick={onClose} variant="outline" color="secondary">
          Cancel
        </Button>
        <Button leftIcon={<MinusIcon />} onClick={onUnassign} colorScheme="error">
          Unassign
        </Button>
      </Flex>
    </Dialog>
  );
}

function AssignComponentDialog({
  onClose,
  portfolioId,
  plantViewId,
  plantName,
  originalName,
  component_type,
  component_id
}: ComponentDialogProps) {
  const assignComponent = useAssignComponent(portfolioId, plantViewId);
  const onAssign = async () => {
    return await new Promise<boolean>(resolve => {
      assignComponent.mutate(
        { portfolioId, plantViewId, component_type, component_id },
        {
          onSuccess: () => {
            onClose();
            resolve(true);
          },
          onError: () => resolve(false)
        }
      );
    });
  };

  return (
    <Dialog title={`Assign ${originalName} to ${plantName}`} isOpen={true} onClose={onClose}>
      <Text fontSize="md">{`Are you sure you want to assign the component ${originalName} from the plant ${plantName}`}</Text>

      <Flex justify="flex-end" gap={3} mt="6">
        <Button onClick={onClose} variant="outline" color="secondary">
          Cancel
        </Button>
        <Button leftIcon={<PlusIcon />} onClick={onAssign} colorScheme="primary">
          Assign
        </Button>
      </Flex>
    </Dialog>
  );
}

const formSchema = z.object({
  name: z.string().optional(),
  external_id: z.string().optional()
});

type FormSchema = z.infer<typeof formSchema>;

const formFieldLabels: Record<FieldPath<FormSchema>, string> = {
  name: 'Display Name',
  external_id: 'External ID'
};

function EditComponentDialog({ onClose, portfolioId, plantViewId, component_type, component_id, originalName }: ComponentDialogProps) {
  const form = useForm<FormSchema>({
    mode: 'onBlur',
    defaultValues: {
      name: originalName,
      external_id: component_id
    },
    resolver: zodResolver(formSchema)
  });

  const { dirtyFields, defaultValues: defaultFormValues } = form.formState;
  const formValues = form.watch();

  const formChangesDialog = useFormChangesDialog({
    formValues,
    dirtyFields,
    defaultValues: defaultFormValues,
    formFieldLabels
  });

  const handleBeforeSubmit = async () => {
    if (await formChangesDialog()) {
      return true;
    }
    return false;
  };

  const updateComponent = useUpdateComponent(portfolioId, plantViewId);
  const handleSubmit = async (data: FormSchema) => {
    return await new Promise<boolean>(resolve => {
      updateComponent.mutate(
        {
          portfolioId,
          plantViewId,
          component_type,
          component_id,
          body: { new_component_id: data.external_id ?? component_id, new_component_name: data.name ?? originalName }
        },
        {
          onSuccess: () => {
            onClose();
            resolve(true);
          },
          onError: () => resolve(false)
        }
      );
    });
  };

  return (
    <Dialog title="Manage Component Info" isOpen={true} onClose={onClose}>
      <Form form={form} onValidSubmit={handleSubmit} onBeforeSubmit={handleBeforeSubmit} py="2" unsavedChangesDialog={false}>
        <Flex flexDir="column" gap="2">
          <FormInput control={form.control} name="name" fieldName="name" label={formFieldLabels.name} placeholder="Display Name" />
          <FormInput
            control={form.control}
            name="external_id"
            fieldName="external_id"
            label={formFieldLabels.external_id}
            placeholder="External ID"
          />
        </Flex>
      </Form>

      <Flex justify="flex-end" gap={3} mt="6">
        <Button onClick={onClose} variant="outline" color="secondary">
          Cancel
        </Button>
        <Button leftIcon={<SaveIcon />} onClick={() => handleSubmit(formValues)} colorScheme="primary">
          Save
        </Button>
      </Flex>
    </Dialog>
  );
}
