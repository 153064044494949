import { Skeleton } from '@chakra-ui/react';
import { useId } from 'react';
import { type FieldValues } from 'react-hook-form';
import { Select, type SelectProps } from '../../../Select/Select';
import { FormControl, extractFormControlProps, type FormControlProps } from '../../FormControl/FormControl';

export interface FormSelectProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, 'children'>,
    Omit<SelectProps, 'value' | 'onChange' | 'isInvalid'> {
  isNumeric?: boolean;
  isLoading?: boolean;
}

export function FormSelect<T extends FieldValues>({ isNumeric = false, isLoading = false, ...props }: FormSelectProps<T>) {
  const { controlProps, inputProps } = extractFormControlProps<T, FormSelectProps<T>>(props);
  const generatedId = useId();

  return (
    <FormControl {...controlProps} htmlFor={generatedId}>
      {({ isDisabled, value, onChange }) =>
        isLoading ? (
          <Skeleton rounded="base" h={10.5} />
        ) : (
          <Select
            id={generatedId}
            isDisabled={isDisabled}
            {...inputProps}
            value={value ?? ''}
            onChange={value => {
              onChange(isNumeric ? Number(value) : value);
            }}
          />
        )
      }
    </FormControl>
  );
}
