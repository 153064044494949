import { Box, useMultiStyleConfig, type BoxProps, type ThemingProps } from '@chakra-ui/react';
import type { ReactNode } from 'react';

export interface TagProps extends BoxProps, ThemingProps<'Tag'> {
  leftIcon?: ReactNode;
  children: ReactNode;
}

export function Tag({ variant, size, colorScheme = 'secondary', children, leftIcon, ...boxProps }: TagProps) {
  const styles = useMultiStyleConfig('Tag', { variant, size, colorScheme });

  return (
    <Box {...boxProps} __css={styles.container}>
      {Boolean(leftIcon) && <Box __css={styles.icon}>{leftIcon}</Box>}
      <Box as="div" __css={styles.text}>
        {children}
      </Box>
    </Box>
  );
}
