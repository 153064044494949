import { useTheme } from '@hydrogrid/design-system';
import { motion } from 'framer-motion';
import styles from './TopologyGraphNode.module.css';
import { NODE_RADIUS, type GraphNodeData } from './topologyGraphUtils';
import { TOPOLOGY_ANIM_DURATION } from './useTopologyGraphData';

const FRAMER_TRANSITION = {
  bounce: 0,
  duration: TOPOLOGY_ANIM_DURATION
};

interface Props {
  graphId: string;
  isHighlighted?: boolean;
  isFiltered?: boolean;
  isVisible: boolean;
  x: number;
  y: number;
  data: GraphNodeData;
}

export function TopologyGraphNode({ graphId, isHighlighted = false, isFiltered = false, isVisible, data, x, y }: Props) {
  const theme = useTheme();
  const isMergedUnit = data.mergedNodes.length > 0;

  const icon = (
    <use
      href={`#${graphId}-${data.type}`}
      color={data.isPartOfPlant ? (isFiltered ? theme.colors.info : theme.colors.primary) : theme.colors.secondary}
      x={-NODE_RADIUS}
      y={-NODE_RADIUS}
      width={NODE_RADIUS * 2}
      height={NODE_RADIUS * 2}
    />
  );

  return (
    <motion.g initial={{ x, y, opacity: 0 }} animate={{ x, y, opacity: isVisible ? 1 : 0 }} transition={FRAMER_TRANSITION}>
      <rect x={-NODE_RADIUS * 1.5} y={-NODE_RADIUS * 1.5} width={NODE_RADIUS * 3} height={NODE_RADIUS * 3} fill="transparent" />
      <motion.g
        animate={{
          x: isMergedUnit ? -2.5 : 0,
          y: isMergedUnit ? -2.5 : 0,
          scale: isMergedUnit ? 0.9 : 1
        }}
        transition={FRAMER_TRANSITION}
      >
        {icon}
      </motion.g>
      <motion.g
        initial={{ opacity: 0 }}
        animate={{
          opacity: isMergedUnit ? 1 : 0
        }}
        transition={FRAMER_TRANSITION}
      >
        <motion.g
          animate={{
            x: isMergedUnit ? 2.5 : 0,
            y: isMergedUnit ? 2.5 : 0,
            scale: 0.9
          }}
          transition={FRAMER_TRANSITION}
        >
          {icon}
        </motion.g>
        <foreignObject transform="translate(9, -30)" width="1" height="1" overflow="visible">
          <div className={styles.mergeCountBadge}>{data.mergedNodes.length + 1}</div>
        </foreignObject>
      </motion.g>
      <foreignObject transform="translate(-65, 0)" width="1" height="1" overflow="visible">
        <div className={styles.nodeLabel} style={{ fontWeight: isHighlighted ? 'bold' : undefined }}>
          <p>{isMergedUnit ? `${data.mergedNodes.length + 1} ${data.type.toUpperCase()}S` : data.type.toUpperCase()}</p>
          {!isMergedUnit && <p>{data.name}</p>}
        </div>
      </foreignObject>
    </motion.g>
  );
}
