import { motion } from 'framer-motion';
import { LINK_COLOR_ACTIVE, LINK_COLOR_INACTIVE, TOPOLOGY_ANIM_DURATION } from './useTopologyGraphData';

interface Props {
  graphId: string;
  isHighlighted?: boolean;
  isVisible: boolean;
  isActive?: boolean;
  pathLine: string;
}

export function TopologyGraphLink({ graphId, isHighlighted = false, isActive = true, isVisible, pathLine }: Props) {
  return (
    <motion.path
      initial={{ d: pathLine, opacity: 0 }}
      animate={{ d: pathLine, opacity: isVisible ? 1 : 0 }}
      transition={{
        bounce: 0,
        duration: TOPOLOGY_ANIM_DURATION
      }}
      fill="none"
      strokeWidth={isHighlighted ? 3 : 2}
      stroke={isActive ? LINK_COLOR_ACTIVE : LINK_COLOR_INACTIVE}
      markerEnd={`url(#${graphId}-ARROWHEAD)`}
    />
  );
}
