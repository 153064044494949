import {
  Box,
  Center,
  Accordion as ChakraAccordion,
  AccordionButton as ChakraAccordionButton,
  AccordionItem as ChakraAccordionItem,
  Icon,
  type AccordionProps,
  type AccordionItemProps as ChakraAccordionItemProps
} from '@chakra-ui/react';
import type { PropsWithChildren, ReactNode } from 'react';
import { ArrowRightIcon } from '../../icons';

export { AccordionPanel } from '@chakra-ui/react';

export function Accordion({ allowMultiple = true, ...props }: AccordionProps) {
  return (
    <ChakraAccordion allowMultiple={allowMultiple} display="flex" flexDir="column" gap={props.variant === 'solid' ? 6 : 0} {...props} />
  );
}

interface AccordionItemProps extends Omit<ChakraAccordionItemProps, 'children'> {
  text: ReactNode;
  isTable?: boolean;
  isLazy?: boolean;
}

export function AccordionItem({ children, text, isTable = false, isLazy = false, ...props }: PropsWithChildren<AccordionItemProps>) {
  return (
    <ChakraAccordionItem {...props}>
      {({ isExpanded }) => {
        const showContent = isLazy ? isExpanded : true;

        return (
          <Box as={isTable ? 'td' : 'span'} p={isTable ? 0 : {}}>
            <ChakraAccordionButton>
              <Box as="span" flex="1" textAlign="left">
                {text}
              </Box>
              <Center h={6} w={5}>
                <Icon as={ArrowRightIcon} transform={`rotate(${isExpanded ? 90 : 0}deg)`} transition="transform 0.2s" />
              </Center>
            </ChakraAccordionButton>
            {showContent && children}
          </Box>
        );
      }}
    </ChakraAccordionItem>
  );
}
