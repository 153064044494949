import { Flex } from '@chakra-ui/react';
import { HeaderSection, ScrollPanel } from '@hydrogrid/design-system';
import { stringToCapitalize } from '@hydrogrid/utilities/string/stringCase';
import { useMemo } from 'react';
import type { ComponentType, InExTernalComponentList } from '../../../../common/api/generated-client/Schemas';
import { useFrontEndTopology } from '../../../../common/api/hooks/CommonDataHooks';
import { useComponents } from '../../../../common/api/hooks/internalDataHooks';
import { routes } from '../../../../common/routing/routes';
import { useAppRoute } from '../../../../common/routing/useAppRoute';
import ComponentList from './ComponentList';

export function ComponentTables() {
  const route = useAppRoute(routes.managePlantView);
  const { portfolioId, plantId } = route.params;

  const topology = useFrontEndTopology(portfolioId, plantId);
  const components = useComponents(portfolioId, plantId);

  const hydroBodies: InExTernalComponentList = useMemo(() => {
    const hydroBodyMap = topology.data?.hydro_bodies.map(hydroBody => {
      if (hydroBody.type === 'DOWNSTREAM') return;

      const match = components.data?.find(component => component.external_id === hydroBody.id);

      if (match != null) {
        return match;
      }

      return {
        internal_id: hydroBody.id,
        external_id: hydroBody.redirect_id ?? '',
        name: hydroBody.name,
        type: stringToCapitalize(hydroBody.type) as ComponentType
      };
    });

    return hydroBodyMap?.filter(component => component !== undefined) ?? [];
  }, [components.data, topology.data?.hydro_bodies]);

  const controlUnits: InExTernalComponentList = useMemo(() => {
    const controlUnitMap = topology.data?.control_units.map(controlUnit => {
      const match = components.data?.find(component => component.external_id === controlUnit.id);

      if (match != null) {
        return match;
      }

      return {
        internal_id: controlUnit.id,
        external_id: controlUnit.redirect_id ?? '',
        name: controlUnit.name ?? '',
        type: stringToCapitalize(controlUnit.type) as ComponentType
      };
    });

    return controlUnitMap?.filter(component => component !== undefined) ?? [];
  }, [components.data, topology.data?.control_units]);

  return (
    <ScrollPanel fitContent height="100%">
      <Flex h="100%" gap={6} flexDirection="column">
        <Flex gap={2} flexDirection="column">
          <HeaderSection title="Hydro Bodies" size="sm" />
          <ComponentList queryStatus={topology.status} components={hydroBodies ?? []} onRetry={topology.refetch} />
        </Flex>
        <Flex gap={2} flexDirection="column">
          <HeaderSection title="Control Units" size="sm" />
          <ComponentList queryStatus={topology.status} components={controlUnits ?? []} onRetry={topology.refetch} />
        </Flex>
      </Flex>
    </ScrollPanel>
  );
}
