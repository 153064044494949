import { Divider, Flex, IconButton, Text, useDisclosure, type BoxProps } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionPanel, Button, Card, MinusIcon, PlusIcon } from '@hydrogrid/design-system';
import { forwardRef } from 'react';
import type { PhysicalPlant } from '../../../../common/api/generated-client/Schemas';
import { useAssignPlant } from '../../../../common/api/hooks/internalDataHooks';
import { routes } from '../../../../common/routing/routes';
import { useAppRoute } from '../../../../common/routing/useAppRoute';
import PlantViewRow from './PlantViewRow';
import { UnassignPlantDialog } from './UnassignPlantDialog';

interface Props extends BoxProps {
  plant: PhysicalPlant;
  isHighlighted: boolean;
}

export const PortfolioPlantCard = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const route = useAppRoute(routes.portfolioDetails);
  const { portfolioId } = route.params;
  const { plant, isHighlighted, ...boxProps } = props;
  const { name, plant_views, internal_id } = plant;

  const unassignPlantDisclosure = useDisclosure();

  const assignPlant = useAssignPlant();
  const handleAssignPlant = async () => {
    return new Promise<boolean>(resolve => {
      resolve(true);
      assignPlant.mutate(
        { portfolioId, plantId: internal_id },
        {
          onSuccess: () => {
            resolve(true);
          },
          onError: () => resolve(false)
        }
      );
    });
  };

  return (
    <>
      <Card key={internal_id} ref={ref} p={0} {...boxProps} mb="auto">
        <Flex align="flex-start" justify="space-between" gap={2} p={3} bg={isHighlighted ? 'primary.50' : ''}>
          <Flex gap={2} overflow="hidden">
            <Text fontWeight="bold" isTruncated>
              {name}
            </Text>{' '}
            <Text as="span" fontWeight="normal">
              ({internal_id})
            </Text>
          </Flex>
          <IconButton
            minW={0}
            w={5}
            h={5}
            variant="link"
            colorScheme="error"
            icon={<MinusIcon />}
            aria-label="remove plant"
            onClick={unassignPlantDisclosure.onOpen}
          />
        </Flex>

        <Divider />
        <Flex>
          <Accordion variant="unstyled" w="full">
            <AccordionItem
              border="none"
              px={3}
              py={2}
              _hover={{ bg: 'secondary.50' }}
              text={
                <Flex align="center" justify="space-between" gap={1}>
                  <Text>{`${plant_views.length} plant view${plant_views.length > 1 ? 's' : ''}`}</Text>
                </Flex>
              }
            >
              <AccordionPanel mt={2} p={0} display="flex" flexDir="column" gap={1}>
                <Flex gap={1} flexDirection="column" transition="height 0.2s">
                  {plant_views.map(({ external_id, name }, index) => {
                    return <PlantViewRow key={index} external_id={external_id ?? ''} name={name ?? ''} isLast={plant_views.length === 1} />;
                  })}
                </Flex>

                <Flex justify="flex-end" mt="2">
                  <Button as={Button} leftIcon={<PlusIcon />} variant="outline" colorScheme="primary" onClick={handleAssignPlant}>
                    Add Plant View
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      </Card>

      {unassignPlantDisclosure.isOpen && <UnassignPlantDialog onClose={unassignPlantDisclosure.onClose} portfolioId={portfolioId} />}
    </>
  );
});
