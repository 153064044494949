import {
  Box,
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  useDisclosure,
  type FormControlProps as ChakraFormControlProps
} from '@chakra-ui/react';
import { type ReactNode } from 'react';
import { Controller, useFormContext, type Control, type FieldPath, type FieldValues, type Path, type PathValue } from 'react-hook-form';
import { QuestionIcon } from '../../../icons';
import { Button } from '../../Button/Button';
import { Dialog, type DialogProps } from '../../Dialog/Dialog';

export function extractFormControlProps<T extends FieldValues, P extends Omit<FormControlProps<T>, 'children'>>(props: P) {
  const {
    control,
    fieldName,
    label,
    isLabelHidden = false,
    isInvalid,
    isDisabled = false,
    isRequired = false,
    hideHelperText = false,
    infoDialogProps,
    revalidates,
    display = undefined as never,
    width = undefined as never,
    ...inputProps
  } = props;

  return {
    controlProps: {
      display,
      width,
      control,
      fieldName,
      label,
      isLabelHidden,
      isInvalid,
      isDisabled,
      hideHelperText,
      isRequired,
      infoDialogProps,
      revalidates
    },
    inputProps
  };
}

export interface FormControlProps<T extends FieldValues> extends Pick<ChakraFormControlProps, 'display' | 'width'> {
  control: Control<T>;
  fieldName: FieldPath<T>;

  /**
   * Fields specified in this array will be revalidated when the value of this FormControl changes.
   */
  revalidates?: Array<FieldPath<T>> | undefined;

  label?: string | undefined;
  isLabelHidden?: boolean;
  infoDialogProps?: Omit<DialogProps, 'isOpen' | 'onClose'> | undefined;
  isInvalid?: (() => boolean | undefined) | boolean | undefined;
  isDisabled?: boolean;
  isRequired?: boolean;
  htmlFor?: string;
  hideHelperText?: boolean;
  children: (arg: {
    isDisabled: boolean;
    isInvalid: boolean;
    value: PathValue<T, Path<T>>;
    onChange: (data: unknown) => void;
  }) => ReactNode;
}

export function FormControl<T extends FieldValues>(props: FormControlProps<T>) {
  const {
    label,
    isLabelHidden = false,
    isInvalid: isInvalidProp,
    isRequired = false,
    isDisabled: isDisabledProp = false,
    hideHelperText = false,
    fieldName,
    control,
    infoDialogProps,
    revalidates,
    htmlFor,
    children,
    ...controlProps
  } = props;
  const form = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { value, onChange, disabled }, fieldState: { invalid, error, isDirty }, formState: { isSubmitting } }) => {
        const isFieldInvalid = typeof isInvalidProp === 'function' ? (isInvalidProp() ?? invalid) : (isInvalidProp ?? invalid);
        const isFieldDisabled = Boolean(disabled) || isSubmitting || isDisabledProp;

        return (
          <ChakraFormControl isInvalid={isFieldInvalid} isRequired={isRequired} isDisabled={isFieldDisabled} {...controlProps}>
            {label && (
              <FormLabel
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                htmlFor={htmlFor}
                color={isDirty ? 'info.400' : ''}
                gap={2}
                opacity={isLabelHidden ? 0 : 1}
              >
                {isDirty && <Box background="info.400" borderRadius="full" h={2} w={2} />}
                {label}
                {infoDialogProps !== undefined && <FormControlInfoIcon infoDialogProps={infoDialogProps} />}
              </FormLabel>
            )}
            {children({
              isInvalid: isFieldInvalid,
              isDisabled: isFieldDisabled,
              value,
              onChange: value => {
                onChange(value);
                if (form.formState.isSubmitted && revalidates) {
                  void form.trigger(revalidates);
                }
              }
            })}
            {isFieldInvalid ? (
              <FormErrorMessage>{error?.message ?? 'Invalid Field.'}</FormErrorMessage>
            ) : hideHelperText ? null : (
              <FormHelperText>&nbsp;</FormHelperText>
            )}
          </ChakraFormControl>
        );
      }}
    />
  );
}

function FormControlInfoIcon({ infoDialogProps }: { infoDialogProps: Omit<DialogProps, 'isOpen' | 'onClose'> }) {
  const infoDialogDisclosure = useDisclosure();

  return (
    <>
      <Box
        type="button"
        as="button"
        onClick={infoDialogDisclosure.onOpen}
        fontSize="sm"
        _focusVisible={{ outline: 'none', color: 'primary' }}
      >
        <Icon as={QuestionIcon} size="font" />
      </Box>
      {infoDialogDisclosure.isOpen && (
        <Dialog
          footer={
            <Button colorScheme="secondary" onClick={infoDialogDisclosure.onClose}>
              Close
            </Button>
          }
          {...infoDialogProps}
          {...infoDialogDisclosure}
        />
      )}
    </>
  );
}
