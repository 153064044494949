import { Table as ChakraTable, type TableProps as ChakraTableProps } from '@chakra-ui/react';

export interface TableProps extends ChakraTableProps {
  highlightOnHover?: boolean;
}

export function Table({ highlightOnHover = true, sx, ...props }: TableProps) {
  return (
    <ChakraTable
      {...props}
      display="grid"
      sx={{ ...sx, ...(highlightOnHover ? { 'tbody tr': { _hover: { bg: 'secondary.100', td: { bg: 'secondary.100' } } } } : {}) }}
    />
  );
}
