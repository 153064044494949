import type { DataRange } from '@hydrogrid/design-system';
import type { useOptimalMaintenace } from '../hooks/MaintenancesDataHooks';
import { api } from '../InsightApi';
import { dataRangeToBackendParameters } from '../query-utils/dataRangeToBackendParameters';
import { defineQuery } from '../query-utils/defineQuery';

export const plantMaintenances = defineQuery(
  ({ portfolioId, plantId, range, timeZone }: { portfolioId: string; plantId: string; range: DataRange; timeZone: string }) => ({
    queryKey: ['maintenances', portfolioId, 'plant', plantId, range, timeZone],
    queryFn: async ({ signal }) => {
      const { granularity: _remove, ...queryParams } = dataRangeToBackendParameters(range, timeZone);

      const response = await api.dashboard.outage.requestPlantOutages({
        portfolio_id: portfolioId,
        plant_id: plantId,
        queryParams,
        signal
      });
      return response.data;
    }
  })
);

export const maintenance = defineQuery(({ portfolioId, plantId, uuid }: { portfolioId: string; plantId: string; uuid: string }) => ({
  queryKey: ['maintenances', portfolioId, 'plant', plantId, 'uuid', uuid],
  queryFn: ({ signal }) =>
    api.dashboard.outage
      .requestOutage({ portfolio_id: portfolioId, plant_id: plantId, maintenance_id: uuid, signal })
      .then(response => response.data)
}));

export const maintenanceHistory = defineQuery(({ uuid }: { uuid: string }) => ({
  queryKey: ['maintenances', 'history', uuid],
  queryFn: ({ signal }) => api.dashboard.outage.requestOutageHistory({ maintenance_id: uuid, signal }).then(response => response.data)
}));

export const optimalMaintenance = defineQuery(
  ({ portfolioId, plantId, turbineId, queryParams, enabled }: Parameters<typeof useOptimalMaintenace>[0]) => ({
    queryKey: [
      'maintenances',
      portfolioId,
      'plant',
      plantId,
      'optimal',
      'turbine',
      turbineId,
      'start-ts',
      queryParams['start-ts'],
      'end-ts',
      queryParams['end-ts'],
      'duration',
      queryParams['duration-value'],
      'durationUnit',
      queryParams['duration-unit'],
      'pMax',
      queryParams['maintenance-p-max']
    ],
    queryFn: ({ signal }) =>
      api.dashboard.outage.requestOptimalMaintenanceTimings({
        portfolio_id: portfolioId,
        plant_id: plantId,
        turbine_id: turbineId,
        queryParams,
        signal
      }),
    enabled: enabled ?? true
  })
);
