import { classNames, useAnimatablePresence, useCombinedRefs } from '@hydrogrid/design-system';
import { useRef } from 'react';
import WaterCircleLoadingAnimation from '../../assets/animations/water-circle-loading.svg?react';
import styles from './TopologyGraphLoadingAnimation.module.css';

interface TopologyGraphLoadingAnimationProps {
  className?: string;
  loading?: boolean;
}

export function TopologyGraphLoadingAnimation({ className, loading = true }: TopologyGraphLoadingAnimationProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const { shouldRender, ref: animationRef } = useAnimatablePresence({ isOpen: loading });
  const ref = useCombinedRefs(containerRef, animationRef);

  if (!shouldRender) {
    return null;
  }

  return (
    <div ref={ref} className={classNames(styles.container, loading ? styles.appear : styles.vanish, className)}>
      <div>
        <WaterCircleLoadingAnimation width={40} height={40} />
      </div>
    </div>
  );
}
