import { Box, Flex, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Skeleton } from '@chakra-ui/react';
import {
  ArrowDownIcon,
  Breadcrumb,
  Button,
  Dropdown,
  Input,
  MagnifierIcon,
  ScrollPanel,
  TextEllipsis,
  WarningTriangleIcon,
  keyCombination,
  useClickOutside
} from '@hydrogrid/design-system';
import { getPlantId } from '@hydrogrid/utilities/plant';
import type { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState, type KeyboardEvent } from 'react';
import { Link } from 'react-router-dom';
import { handleListKeyboardNavigation } from '../../common/accessibility/handleListKeyboardNavigation';
import type { TypedRoute } from '../../common/routing/TypedRoutes';
import { useListSearch } from '../../common/utils/useListSearch';

interface DropdownNavigationBreadcrumbProps<T extends { internal_id: string; name: string } | { id: string; name: string }> {
  /** List of items created via `useQuery()`. */
  resource: UseQueryResult<T[] | undefined>;

  /** `id` of the current selected item. */
  currentId: string;

  /** Called when the user selects an item from the dropdown. */
  onChange: (newId: string) => void;

  /** @example "Select a portfolio" */
  label: string;

  /** @example "portfolio-breadcrumb" */
  id: string;

  /** @default false */
  disabled?: boolean;

  /** @default 3 */
  minItemsForSearch?: number;

  /**
   * Displayed on error.
   * @default "Loading failed."
   */
  loadingFailedMessage?: string;

  /**
   * Displayed when no items match the search term.
   * @default "No items" // --> "No items match <search term>"
   */
  noItemsMessage?: string;

  /** Set to the return value of an `route.link()` call. */
  linkToId?: (id: string) => ReturnType<TypedRoute['link']> | undefined;

  /**
   * If set to true and the {@link linkToId} prop is provided, the current selected item in the breadcrumb bar
   * will be rendered as a link, allowing users to open the current page in a new tab.
   *
   * @default true
   */
  linkCurrent?: boolean;

  /**
   * If set to true and the {@link linkToId} prop is provided, all items in the dropdown
   * will be rendered as a link, allowing users to open them in a new tab.
   *
   * @default true
   */
  linksInList?: boolean;

  /**
   * It defines if the breadcrumb can act as a link or not
   *
   * @default false
   */
  renderAsLink?: boolean;
}

export function DropdownNavigationBreadcrumb<T extends { internal_id: string; name: string } | { id: string; name: string }>({
  resource,
  currentId,
  onChange,
  id,
  label,
  disabled = false,
  minItemsForSearch = 3,
  loadingFailedMessage = 'Loading failed.',
  noItemsMessage = 'No items',
  linkToId,
  linkCurrent = true,
  linksInList = true,
  renderAsLink = false
}: DropdownNavigationBreadcrumbProps<T>) {
  const allItems = resource.data ?? [];

  const [showErrorPopover, setShowErrorPopover] = useState(false);

  const errorButtonRef = useRef<HTMLDivElement>(null);
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  const currentItem = allItems.find(item => getPlantId(item) === currentId);
  const hasMultipleItems = (allItems.length ?? 0) >= 2;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { matching, searchText, setSearchText, clearSearch } = useListSearch(allItems);

  useEffect(() => {
    if (allItems.length < minItemsForSearch) {
      setSearchText('');
    }
  }, [allItems.length, minItemsForSearch, setSearchText]);

  useEffect(() => {
    if (isDropdownOpen) {
      setSearchText('');
    }

    const scrollingTimeout = setTimeout(() => {
      if (isDropdownOpen && selectedRef.current) {
        selectedRef.current.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'center' });
      }
    }, 100);

    return () => clearTimeout(scrollingTimeout);
  }, [isDropdownOpen, setSearchText, selectedRef]);

  const selectItem = useCallback(
    (newItemId: string) => {
      setIsDropdownOpen(false);
      onChange(newItemId);
    },
    [onChange]
  );

  const closeOnEscape = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const key = keyCombination(event);
    if (key === 'Escape') {
      setIsDropdownOpen(false);
      event.preventDefault();
    } else if (key === 'ArrowDown') {
      const firstOption = listRef.current?.querySelector<HTMLDivElement>('[role="option"]:first-child');
      if (firstOption) {
        firstOption.focus();
        event.preventDefault();
      }
    }
  }, []);

  useEffect(() => {
    if (resource.status === 'success') {
      setShowErrorPopover(false);
    }
  }, [resource.status]);

  useClickOutside({
    enabled: resource.isError && showErrorPopover,
    ref: errorButtonRef,
    handler: () => {
      setShowErrorPopover(false);
    }
  });

  const itemKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const key = keyCombination(event);
    if (key === 'Escape') {
      setIsDropdownOpen(false);
      event.preventDefault();
    } else if (key === 'ArrowUp' && event.currentTarget === event.currentTarget.parentElement?.firstChild) {
      searchBoxRef.current?.focus();
      event.preventDefault();
    }

    if (event.defaultPrevented) return;

    handleListKeyboardNavigation(event);
  }, []);

  const displayValueLinkClicked = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      onChange(currentId);
    },
    [currentId, onChange]
  );

  useClickOutside({
    enabled: isDropdownOpen,
    ref: dropdownRef,
    handler: event => {
      if (arrowRef.current && event.target instanceof Node && !arrowRef.current.contains(event.target)) {
        return setIsDropdownOpen(false);
      }
    }
  });

  const linkPropsForCurrentItem = linkCurrent ? linkToId?.(currentId) : undefined;
  const textOrLink =
    linkPropsForCurrentItem && renderAsLink === true ? (
      <Box
        as={Link}
        {...linkPropsForCurrentItem}
        tabIndex={hasMultipleItems ? -1 : 0}
        replace={true}
        aria-disabled={disabled ? true : undefined}
        onClick={disabled ? undefined : displayValueLinkClicked}
        cursor="default"
        fontWeight="bold"
        transition="opacity 0.2s"
        _hover={{ opacity: disabled ? 1 : 0.7, cursor: disabled ? 'default' : 'pointer' }}
      >
        {currentItem?.name ?? currentId}
      </Box>
    ) : (
      <>{currentItem?.name ?? currentId}</>
    );

  const listItemLinkClicked = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
  }, []);

  if (resource.isPending) {
    return (
      <Breadcrumb>
        <Skeleton w={24} h={4} />
      </Breadcrumb>
    );
  }

  if (resource.isError) {
    return (
      <Breadcrumb>
        <Flex align="center" gap={1}>
          {`${currentItem?.name ?? currentId} `}
          <Flex ref={errorButtonRef}>
            <Popover>
              <PopoverTrigger>
                <Button
                  variant="link"
                  colorScheme={resource.isFetching ? 'secondary' : 'error'}
                  onClick={() => setShowErrorPopover(show => !show)}
                >
                  <WarningTriangleIcon aria-label="Error icon (Loading failed)" />
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent w="auto">
                  <PopoverArrow />
                  <PopoverBody>
                    {`${loadingFailedMessage} `}
                    <Button colorScheme="secondary" variant="link" isDisabled={resource.isFetching} onClick={() => resource.refetch()}>
                      Retry
                    </Button>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Flex>
        </Flex>
      </Breadcrumb>
    );
  }

  if (!hasMultipleItems) {
    return <Breadcrumb>{textOrLink}</Breadcrumb>;
  }

  return (
    <Breadcrumb>
      <TextEllipsis>{textOrLink}</TextEllipsis>

      <Popover
        isOpen={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
        onOpen={() => !disabled && setIsDropdownOpen(true)}
        initialFocusRef={searchBoxRef}
      >
        <PopoverTrigger>
          <Box
            as={Dropdown}
            variant="borderless"
            id={id}
            label={label}
            displayValuePadding={false}
            triggerAutoResize
            maxWidth="20rem"
            disabled={disabled}
            align="middle"
            ml={1}
            mr={-2}
            maxW={72}
            showArrow={false}
            showBorder={false}
            isOpen={isDropdownOpen}
            ref={arrowRef}
            displayValue={
              <Flex
                align="center"
                p="0.75rem 0.5rem "
                borderWidth="1px"
                borderColor={isDropdownOpen ? 'secondary.200' : 'transparent'}
                _hover={{ borderColor: disabled ? 'transparent' : 'secondary.200', cursor: disabled ? 'not-allowed' : 'pointer' }}
                borderRadius="0.25rem"
                color={disabled ? 'secondary.400' : 'secondary'}
              >
                <Icon as={ArrowDownIcon} transform={isDropdownOpen ? 'rotateZ(-180deg)' : {}} transition="transform 0.2s ease-out" />
              </Flex>
            }
          />
        </PopoverTrigger>

        <Portal>
          <PopoverContent mt={-3}>
            <Box ref={dropdownRef}>
              {allItems.length >= minItemsForSearch && (
                <Flex p={2} justify="stretch">
                  <Input
                    addonRight={<MagnifierIcon />}
                    placeholder="Search"
                    ref={searchBoxRef}
                    role="search"
                    value={searchText}
                    onChange={setSearchText}
                    onKeyDown={closeOnEscape}
                    autoFocus
                  />
                </Flex>
              )}
              <ScrollPanel fitContent axis="vertical" maxHeight="50vh" maxWidth="min(50rem, 96vw)">
                <Flex
                  ref={listRef}
                  role="listbox"
                  aria-multiselectable={false}
                  p={2}
                  pt={0}
                  flexDir="column"
                  minW="min(20rem, 80vw)"
                  fontWeight="normal"
                >
                  {matching.map(item => {
                    const plantId = getPlantId(item);
                    const listRowLinkProps = linksInList && plantId ? linkToId?.(plantId) : undefined;

                    return (
                      <Flex
                        key={plantId}
                        overflow="hidden"
                        p={2}
                        align="center"
                        height={10}
                        cursor="pointer"
                        ref={plantId === currentId ? selectedRef : null}
                        role="option"
                        tabIndex={0}
                        aria-selected={plantId === currentId}
                        onKeyDown={itemKeyDown}
                        onClick={() => plantId && selectItem(plantId)}
                        _hover={{ bgColor: 'secondary.100' }}
                        bgColor={plantId === currentId ? 'secondary.50' : {}}
                      >
                        {listRowLinkProps ? (
                          <TextEllipsis as={Link} {...listRowLinkProps} onClick={listItemLinkClicked}>
                            {item.name}
                          </TextEllipsis>
                        ) : (
                          item.name
                        )}
                      </Flex>
                    );
                  })}

                  {allItems.length === 0 && (
                    <Flex p={2} align="center" h={10} fontStyle="italic" whiteSpace="pre">{`${noItemsMessage} found.`}</Flex>
                  )}

                  {searchText !== '' && matching.length === 0 && allItems.length > 0 && (
                    <Flex p={2} align="center" h={10} fontStyle="italic" whiteSpace="pre">
                      {`${noItemsMessage} match "`}
                      <Box maxW={48} overflow="hidden" textOverflow="ellipsis">
                        {searchText}
                      </Box>
                      {'" '}
                      <Button variant="link" colorScheme="primary" onClick={clearSearch}>
                        Show all
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </ScrollPanel>
            </Box>
          </PopoverContent>
        </Portal>
      </Popover>
    </Breadcrumb>
  );
}
