import { Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { PencilIcon, TrashIcon } from '@hydrogrid/design-system';
import { Link } from 'react-router-dom';
import { routes } from '../../../../common/routing/routes';
import { useAppRoute } from '../../../../common/routing/useAppRoute';
import { UnassignPlantViewDialog } from './UnassignPlantViewDialog';

interface Props {
  external_id: string;
  name: string;
  isLast: boolean;
}

export default function PlantViewRow({ external_id, name, isLast }: Props) {
  const route = useAppRoute(routes.portfolioDetails);
  const { environment, portfolioId } = route.params;
  const unassignPlantViewDisclosure = useDisclosure();

  return (
    <>
      <Flex justify="space-between" _hover={{ bg: 'secondary.100' }} pt="1" px="2" borderRadius="base">
        <Text>
          <b>{name} </b>
          {external_id && `(${external_id})`}
        </Text>
        <Flex gap={1} align="center">
          <IconButton
            minW={0}
            w={5}
            h={5}
            variant="link"
            colorScheme="error"
            icon={<TrashIcon />}
            _hover={{ cursor: 'pointer', opacity: '0.5' }}
            aria-label="delete plant-view"
            onClick={unassignPlantViewDisclosure.onOpen}
            data-testid={`${external_id}-unassign-plantview-button`}
          />
          <Link
            to={routes.managePlantView.url({
              params: {
                environment,
                portfolioId,
                plantId: external_id
              }
            })}
          >
            <IconButton
              minW={0}
              w={8}
              h={5}
              variant="link"
              colorScheme="secondary"
              icon={<PencilIcon />}
              _hover={{ cursor: 'pointer', opacity: '0.7' }}
              aria-label="edit plant-view"
            />
          </Link>
        </Flex>
      </Flex>

      {unassignPlantViewDisclosure.isOpen && (
        <UnassignPlantViewDialog
          onClose={unassignPlantViewDisclosure.onClose}
          portfolioId={portfolioId}
          plantId={external_id}
          name={name}
          isLast={isLast}
        />
      )}
    </>
  );
}
