import { Skeleton } from '@chakra-ui/react';
import type { FrontendTopology } from '../../../../common/api/FrontendSchemas';
import { useFrontEndTopology } from '../../../../common/api/hooks/CommonDataHooks';
import { TopologyGraph } from '../../../../components/TopologyGraph/TopologyGraph';

interface Props {
  plantViewId?: string | undefined;
  portfolioId: string;
}
export function PlantViewTopology({ plantViewId, portfolioId }: Props) {
  const plantViewTopology = useFrontEndTopology(portfolioId, plantViewId);

  const topology: FrontendTopology | undefined = {
    hydro_bodies: plantViewTopology.data?.hydro_bodies ?? [],
    control_units: plantViewTopology.data?.control_units ?? []
  };

  if (plantViewTopology.isLoading) return <Skeleton h="full" w="full" />;

  return (
    <TopologyGraph
      topology={topology}
      isError={plantViewTopology.isError}
      isPending={plantViewTopology.isPending}
      selectedNodeIds={[]}
      hoveredNodeId={undefined}
    />
  );
}
