import { Box, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { HeaderSection } from '@hydrogrid/design-system';

export function FormConfirmDialog({
  formChanges
}: {
  formChanges: (
    | {
        displayName: string | undefined;
        originalDisplayValue: string | number;
        formDisplayValue: string | number;
      }
    | undefined
  )[];
}) {
  return (
    <Flex flexDir="column" gap="6.5">
      <Text fontSize="base">Are you sure you want to save this changes?</Text>

      <Box>
        <HeaderSection size="sm" title="Your changes" />

        <List mt="3" gap="2" px="2">
          {formChanges?.map(formChange => {
            return (
              <ListItem key={formChange?.displayName} display="flex" flexWrap="nowrap" gap="2">
                <Box flex="1" width="50%">
                  <Text as="span" fontSize="base" color="gray.900" fontWeight="bold" noOfLines={1}>
                    {formChange?.displayName}
                  </Text>
                </Box>
                <Box flex="1">
                  from{' '}
                  <Text as="span" fontSize="base" color="red.500" fontWeight="bold">
                    {formChange?.originalDisplayValue}
                  </Text>{' '}
                  to{' '}
                  <Text as="span" fontSize="base" color="primary" fontWeight="bold">
                    {formChange?.formDisplayValue}
                  </Text>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Flex>
  );
}
