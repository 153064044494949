import { useMemo } from 'react';
import type { FieldPath, FieldValues } from 'react-hook-form';
import { FormConfirmDialog } from '../../../../../frontend/components/FormConfirmDialog/FormConfirmDialog';
import { SaveIcon } from '../../../icons';
import { useConfirmDialog } from '../../Dialog/useConfirmDialog';

export function useFormChangesDialog<T extends FieldValues>({
  formValues,
  dirtyFields,
  defaultValues,
  defaultValuesReadable,
  formFieldLabels,
  formValuesReadable
}: {
  formValues: T;
  dirtyFields: Partial<Record<FieldPath<NoInfer<T>>, boolean | undefined>>;
  defaultValues: NoInfer<Partial<T>> | undefined;
  defaultValuesReadable?: Partial<Record<FieldPath<NoInfer<T>>, string | undefined>>;
  formFieldLabels: Partial<Record<FieldPath<NoInfer<T>>, string>>;
  formValuesReadable?: Partial<Record<FieldPath<NoInfer<T>>, string | undefined>>;
  // formValuesReadable: { [key in keyof NoInfer<T>]: string | undefined };
}) {
  const { confirmDialog } = useConfirmDialog();

  const dirtyFieldsKeys = Object.keys(dirtyFields) as FieldPath<T>[];

  const formChanges = useMemo(() => {
    return dirtyFieldsKeys.map(dirtyField => {
      if (defaultValues === undefined) return;

      const originalValue = Object.entries(defaultValues).find(entry => entry[0] === dirtyField);
      const formValue: unknown = formValues[dirtyField];

      let formDisplayValue: string | number;
      if (typeof formValue === 'number' || typeof formValue === 'string') {
        formDisplayValue = formValue;
      } else if (formValue == null) {
        formDisplayValue = 'null';
      } else {
        formDisplayValue = formValue === true ? 'Yes' : 'No';
      }

      let originalDisplayValue: string | number;
      if (typeof originalValue?.[1] === 'number' || typeof originalValue?.[1] === 'string') {
        originalDisplayValue = originalValue[1];
      } else if (originalValue?.[1] == null) {
        originalDisplayValue = 'null';
      } else {
        originalDisplayValue = originalValue?.[1] === true ? 'Yes' : 'No';
      }

      return {
        displayName: formFieldLabels[dirtyField],
        originalDisplayValue: defaultValuesReadable?.[dirtyField] ?? originalDisplayValue,
        formDisplayValue: formValuesReadable?.[dirtyField] ?? formDisplayValue
      };
    });
  }, [dirtyFieldsKeys, formValues, defaultValues, formFieldLabels, defaultValuesReadable, formValuesReadable]);

  return async () => {
    if (
      await confirmDialog({
        size: 'lg',
        title: 'Save changes',
        description: <FormConfirmDialog formChanges={formChanges} />,
        confirmButtonProps: { colorScheme: 'primary', leftIcon: <SaveIcon /> }
      })
    ) {
      return true;
    }
    return false;
  };
}
