import { Box, Button, Flex } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, Form, FormSelect, PlusIcon } from '@hydrogrid/design-system';
import { useForm, type FieldPath } from 'react-hook-form';
import { z } from 'zod';
import { usePortfolioList } from '../../../../common/api/hooks/CommonDataHooks';
import { useAllPlants, useAssignPlant } from '../../../../common/api/hooks/internalDataHooks';

const formSchema = z.object({
  plantId: z.string()
});
type FormSchema = z.infer<typeof formSchema>;

const formFieldLabels: Record<FieldPath<FormSchema>, string> = {
  plantId: 'Plant Name'
};

interface Props {
  onClose: () => void;
  portfolioId: string;
}

export default function AssignPlantDialog({ onClose: _onClose, portfolioId }: Props) {
  const form = useForm<FormSchema>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema)
  });

  const portfolio = usePortfolioList(portfolioId);
  const portfolioName = portfolio?.data?.[0].name ?? '';

  const plantList = useAllPlants();

  const {
    formState: { isSubmitting }
  } = form;

  const onClose = () => {
    if (isSubmitting) {
      return;
    }

    _onClose();
  };

  const assignPlant = useAssignPlant();
  const handleSubmit = async (data: FormSchema) => {
    return new Promise<boolean>(resolve => {
      resolve(true);
      assignPlant.mutate(
        { portfolioId, plantId: data.plantId },
        {
          onSuccess: () => {
            _onClose();
            resolve(true);
          },
          onError: () => resolve(false)
        }
      );
    });
  };

  return (
    <Dialog title={`Assign a Plant to ${portfolioName}`} size="lg" isOpen={true} onClose={onClose}>
      <Form form={form} gap={6.5} display="flex" flexDirection="column" onValidSubmit={handleSubmit}>
        <Box>
          <FormSelect control={form.control} fieldName="plantId" label={formFieldLabels['plantId']} placeholder="Select a plant">
            <option hidden />
            {plantList.data?.map(plant => {
              return (
                <option key={plant.id} value={plant.id}>
                  {plant.name}
                </option>
              );
            })}
          </FormSelect>
        </Box>
        <Flex justify="flex-end" gap={3}>
          <Button isDisabled={isSubmitting} variant="outline" colorScheme="secondary" onClick={onClose}>
            Cancel
          </Button>

          <Button data-testid="save-btn" isLoading={isSubmitting} leftIcon={<PlusIcon />} type="submit">
            Assign Plant
          </Button>
        </Flex>
      </Form>
    </Dialog>
  );
}
