import { Td as ChakraTd, type TableCellProps } from '@chakra-ui/react';
import { useTableContext } from './TableContext';
import { useTableStore } from './TableStore';

export interface TdProps extends TableCellProps {
  isFirstColumn?: boolean;
}

export function Td({ isFirstColumn = false, ...props }: TdProps) {
  const showShadowLeft = useTableStore(state => state.activeShadows.left);
  const { isFirstColumnSticky } = useTableContext();
  const isSticky = isFirstColumn && isFirstColumnSticky;

  return (
    <ChakraTd
      isTruncated
      {...props}
      {...(isSticky
        ? {
            pos: 'sticky',
            left: 0,
            bg: props.bg ?? 'white',
            zIndex: 1,
            boxShadow: showShadowLeft ? '1px 0px 0px var(--chakra-colors-secondary-200)' : (undefined as never)
          }
        : {})}
    />
  );
}
