import { useLayoutEffect, useReducer, useRef, type ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useOverlayHost } from './OverlayHost';

interface PortalProps {
  children?: ReactNode;

  /**
   * The nearest `OverlayHost` this Portal should be placed in.
   *
   * - "root" places the portal contents in the `body` _(default)_
   * - "closest" uses the closest OverlayHost
   * - any other string references a named OverlayHost
   *
   * @default "root"
   */
  hostId?: 'root' | 'closest' | (string & {});
}

export function Portal({ children, hostId }: PortalProps) {
  const hostRef = useOverlayHost(hostId)?.ref;
  const hadHostOnFirstRender = useRef(hostRef?.current != null);
  const host = hostRef?.current;

  const [, rerenderWhenHostIsAvailable] = useReducer(() => 1, 0);

  // If the host was not rendered at the time the portal is rendered,
  // we need to re-render once after the host element is available.
  useLayoutEffect(() => {
    const host = hostRef?.current;
    if (!host || hadHostOnFirstRender.current) return;

    rerenderWhenHostIsAvailable();
  }, [hostRef]);

  return host && createPortal(children, host);
}
