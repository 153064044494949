import { Tr as ChakraTr, type TableRowProps as ChakraTableRowProps } from '@chakra-ui/react';
import { useTableContext } from './TableContext';

export interface TrProps extends ChakraTableRowProps {}

export function Tr(props: TrProps) {
  const { columnTemplate } = useTableContext();

  return <ChakraTr display="grid" gridTemplateColumns={columnTemplate} {...props} />;
}
