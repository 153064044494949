import { useDrag } from '@hydrogrid/design-system';
import type { MotionValue } from 'framer-motion';
import { useRef } from 'react';
import type { TopologySize } from './topologyGraphUtils';

interface CorrectedCoordinatesProps {
  x: number;
  y: number;
  scale: number;
  graphSize: TopologySize;
  viewportSize: TopologySize;
}

function getCorrectedCoordinates({ x, y, scale, graphSize, viewportSize }: CorrectedCoordinatesProps) {
  let newX = x;
  let newY = y;

  const correctedGraphSize = {
    width: graphSize.width * scale,
    height: graphSize.height * scale
  };
  const paddingSize = {
    x: correctedGraphSize.width / 4,
    y: correctedGraphSize.height / 4
  };

  const minX = -correctedGraphSize.width + paddingSize.x;
  const maxX = viewportSize.width - paddingSize.x;
  const minY = -correctedGraphSize.height + paddingSize.y;
  const maxY = viewportSize.height - paddingSize.y;

  if (newX < minX) {
    newX = minX;
  } else if (newX > maxX) {
    newX = maxX;
  }

  if (newY < minY) {
    newY = minY;
  } else if (newY > maxY) {
    newY = maxY;
  }

  return { x: newX, y: newY };
}

interface Props {
  graphX: MotionValue<number>;
  graphY: MotionValue<number>;
  graphScale: MotionValue<number>;
  graphSize: TopologySize;
  viewportSize: TopologySize;
}

/**
 * This hook encapsulates the dragging and zooming logic of the topology graph.
 */
export function useTopologyDragZoom({ graphX, graphY, graphScale, graphSize, viewportSize }: Props) {
  const startingPos = useRef({ x: 0, y: 0 });

  const { startDrag, isDragging } = useDrag({
    onDragStart: () => {
      startingPos.current = {
        x: graphX.get(),
        y: graphY.get()
      };
    },
    onDrag: (deltaX, deltaY) => {
      const newX = startingPos.current.x + deltaX;
      const newY = startingPos.current.y + deltaY;
      const scale = graphScale.get();
      const { x, y } = getCorrectedCoordinates({ x: newX, y: newY, scale, graphSize, viewportSize });

      graphX.set(x);
      graphY.set(y);
    }
  });

  const onWheelScroll = (e: WheelEvent) => {
    const currentScale = graphScale.get();
    let newScale = currentScale - e.deltaY / 750 / 10;
    if (newScale < 0.1) {
      newScale = 0.1;
    } else if (newScale > 2) {
      newScale = 2;
    }

    const oldWidth = graphSize.width * currentScale;
    const newWidth = graphSize.width * newScale;
    const widthDiff = oldWidth - newWidth;
    const newX = graphX.get() + widthDiff / 2;

    const oldHeight = graphSize.height * currentScale;
    const newHeight = graphSize.height * newScale;
    const heightDiff = oldHeight - newHeight;
    const newY = graphY.get() + heightDiff / 2;

    const { x, y } = getCorrectedCoordinates({ x: newX, y: newY, scale: newScale, graphSize, viewportSize });

    graphScale.set(newScale);
    graphX.set(x);
    graphY.set(y);
  };

  return { startDrag, isDragging, onWheelScroll };
}
