import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppAction } from '../../common/accessibility/useAppActions';
import * as queries from '../../common/api/queries';
import type { AnyTypedRoute, PartialReactRouterLinkProps, UnknownTypedRoute } from '../../common/routing/TypedRoutes';
import { useAppNavigate } from '../../common/routing/useAppNavigate';
import { useAppRoute } from '../../common/routing/useAppRoute';
import { useEffectOnce } from '../../common/utils/useEffectOnce';
import { DropdownNavigationBreadcrumb } from './DropdownNavigationBreadcrumb';

interface PlantBreadcrumbProps {
  /**
   * `true` to make the breadcrumb and items in the dropdown links to the current page.
   * @default true
   */
  createLinks?: boolean;

  /**
   * When set to `true`, selecting a plant will replace the current navigation entry.
   *
   * If you pass a `createLink` callback, you can just return `{ replace: true }`.
   */
  replaceOnSelect?: boolean;

  /**
   * Called when the user selects a plant from the dropdown.
   * Return `false` from the passed function to cancel navigating to that plant.
   */
  onChange?: (newPlantId: string) => boolean | void;

  /**
   * Factory for links to navigate when selecting a plant, items in the list & the current item in the breadcrumb.
   * In most cases, you want to call `someTypedRoute.link()` with the appropriate arguments.
   */
  createLink?: (newPlantId: string) => PartialReactRouterLinkProps;

  /**
   * Route to navigate to when selecting a plant, and for which link are created.
   * Use {@link createLink} for more complex scenarios, e.g. when you want to keep/change some query parameters.
   */
  targetRoute?: AnyTypedRoute;

  /** @default false */
  disabled?: boolean;

  /**
   * It defines if the breadcrumb can act as a link or not
   *
   * @default false
   */
  renderAsLink?: boolean;

  /**
   * Boolean to toggle the display of physical plants list
   *
   * @default false
   */
  showPlantViewList?: boolean;
}

export function PlantBreadcrumb({
  createLinks = true,
  replaceOnSelect,
  disabled = false,
  targetRoute: targetRouteFromProps,
  onChange,
  createLink,
  renderAsLink = false,
  showPlantViewList = false
}: PlantBreadcrumbProps) {
  const currentRoute = useAppRoute('*');
  const { portfolioId, plantId: currentPlantId } = currentRoute.params as Record<string, string>;
  const targetRoute: UnknownTypedRoute = targetRouteFromProps ?? currentRoute.matchedRoute;

  const appNavigate = useAppNavigate();
  const routerNavigate = useNavigate();

  const appRoute = useAppRoute('*');
  const isOnAdminPage = showPlantViewList ? false : /\/(admin)\//.test(appRoute.matchedRoute.path);

  useEffectOnce(() => {
    if (import.meta.env.PROD) return;

    if (!currentRoute.matchedRoute.pathParamNames.includes('plantId')) {
      throw new Error('You used PlantBreadcrumb on a route which does not have a plantId parameter.');
    }

    if (createLink) return;

    if (!targetRoute.pathParamNames.includes('portfolioId')) {
      throw new Error('You used PlantBreadcrumb for a route which does not have a portfolioId parameter.');
    }
    if (!targetRoute.pathParamNames.includes('plantId')) {
      throw new Error('You used PlantBreadcrumb for a route which does not have a plantId parameter.');
    }

    const params = targetRoute.pathParams;
    if (params.slice(params.findIndex(param => param.name === 'plantId') + 1).some(param => !param.optional)) {
      throw new Error(
        'You used PortfolioBreadcrumb for a route which has non-optional parameters after the plantId. Provide the createLink property.'
      );
    }
  });

  const plantViewListQuery = useQuery(queries.plantViewList(portfolioId));
  const plantViews = plantViewListQuery.data ?? [];

  const physicalPlantListQuery = useQuery(queries.physicalPlantList(portfolioId));
  const physicalPlants = physicalPlantListQuery.data ?? [];

  const otherParams = useMemo(() => {
    const otherParams: Record<string, unknown> = {};
    for (const { name, optional } of targetRoute.pathParams) {
      if (optional || name === 'plantId') break;
      otherParams[name] = currentRoute.params[name];
    }

    return otherParams;
  }, [currentRoute, targetRoute]);

  const selectPlant = useCallback(
    (plantId: string) => {
      if (onChange?.(plantId) === false) return;

      if (createLink) {
        const link = createLink(plantId);
        return routerNavigate(link.to, {
          state: link.state as unknown,
          replace: replaceOnSelect ?? link.replace
        });
      }
      appNavigate(targetRoute, { params: { ...otherParams, plantId } }, replaceOnSelect != null ? { replace: replaceOnSelect } : undefined);
    },
    [onChange, createLink, appNavigate, targetRoute, otherParams, replaceOnSelect, routerNavigate]
  );

  const linkToPlantWithId = useCallback(
    (plantId: string) => {
      if (createLink) {
        return createLink(plantId);
      }

      if (plantId == null && targetRoute.pathParams.find(param => param.name === 'plantId' && !param.optional)) {
        return;
      }

      return targetRoute.link({
        params: { ...otherParams, plantId }
      });
    },
    [otherParams, createLink, targetRoute]
  );

  useAppAction({
    id: 'previous-plant',
    name: 'Previous Plant',
    group: 'Plant',
    hotkey: 'Shift+P',
    callback: () => {
      if (isOnAdminPage) {
        const prevPlant = physicalPlants.find((_, index) => physicalPlants[index + 1]?.internal_id === currentPlantId);
        if (prevPlant) {
          selectPlant(prevPlant.internal_id);
        }
      } else {
        const prevPlant = plantViews.find((_, index) => plantViews[index + 1]?.id === currentPlantId);
        if (prevPlant) {
          selectPlant(prevPlant.id);
        }
      }
    }
  });

  useAppAction({
    id: 'next-plant',
    name: 'Next Plant',
    group: 'Plant',
    hotkey: 'P',
    callback: () => {
      if (isOnAdminPage) {
        const nextPlant = physicalPlants.find((_, index) => physicalPlants[index - 1]?.internal_id === currentPlantId);
        if (nextPlant) {
          selectPlant(nextPlant.internal_id);
        }
      } else {
        const nextPlant = plantViews.find((_, index) => plantViews[index - 1]?.id === currentPlantId);
        if (nextPlant) {
          selectPlant(nextPlant.id);
        }
      }
    }
  });

  return (
    <>
      {isOnAdminPage ? (
        <DropdownNavigationBreadcrumb
          resource={physicalPlantListQuery}
          currentId={currentPlantId}
          onChange={selectPlant}
          id="plant-breadcrumb"
          label="Select a plant"
          disabled={disabled}
          loadingFailedMessage="Plants can not be loaded."
          noItemsMessage="No plants"
          linkToId={linkToPlantWithId}
          linkCurrent={createLinks}
          linksInList={createLinks}
          renderAsLink={renderAsLink}
        />
      ) : (
        <DropdownNavigationBreadcrumb
          resource={plantViewListQuery}
          currentId={currentPlantId}
          onChange={selectPlant}
          id="plant-breadcrumb"
          label="Select a plant"
          disabled={disabled}
          loadingFailedMessage="Plants can not be loaded."
          noItemsMessage="No plants"
          linkToId={linkToPlantWithId}
          linkCurrent={createLinks}
          linksInList={createLinks}
          renderAsLink={renderAsLink}
        />
      )}
    </>
  );
}
