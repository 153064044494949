/// <reference types="google.maps" />

// https://developers.google.com/maps/documentation/javascript/style-reference
const mapStyle: google.maps.MapTypeStyle[] = [
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [{ visibility: 'on' }, { color: '#4a5860' }]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'administrative',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }, { color: '#f5f5f6' }]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }, { color: '#ccd2d5' }]
  },
  {
    featureType: 'landscape.natural',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  }
];

const worldBounds: google.maps.LatLngBoundsLiteral = {
  north: 85,
  south: -85,
  east: 180,
  west: -180
};

const mapRestriction: google.maps.MapRestriction = {
  latLngBounds: worldBounds,
  strictBounds: false
};

export const defaultMapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  center: {
    lat: 48,
    lng: 16
  },
  styles: mapStyle,
  restriction: mapRestriction
};
