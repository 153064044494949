import { Avatar, AvatarGroup, Box, Divider, Flex, Grid, Skeleton, Text, useDisclosure } from '@chakra-ui/react';
import {
  Alert,
  BellIcon,
  Breadcrumb,
  BreadcrumbLink,
  Breadcrumbs,
  Button,
  Input,
  MagnifierIcon,
  PageNavigation,
  PlusIcon,
  ScrollPanel,
  UserIcon
} from '@hydrogrid/design-system';
import { repeatJsx } from '@hydrogrid/utilities/react';
import { useMemo, useState } from 'react';
import { ApiError } from '../../../common/api/ApiError';
import { usePhysicalPlantList } from '../../../common/api/hooks/CommonDataHooks';
import { routes } from '../../../common/routing/routes';
import { useAppNavigate } from '../../../common/routing/useAppNavigate';
import { useAppRoute } from '../../../common/routing/useAppRoute';
import { useRouteEnvironment } from '../../../common/routing/useRouteEnvironment';
import { useAuthStore } from '../../../common/stores/AuthStore';
import { BackButton } from '../../../components/BackButton/BackButton';
import { EmptyPlaceholder } from '../../../components/EmptyPlaceholder/EmptyPlaceholder';
import { PortfolioBreadcrumb } from '../../../components/NavigationBreadcrumbs/PortfolioBreadcrumb';
import { PlantsMap } from '../PlantOverview/components/PlantsMap';
import AssignPlantDialog from './components/AssignPlantDialog';
import { PortfolioPlantCard } from './components/PortfolioPlantCard';

export default function PortfolioPage() {
  const { email } = useAuthStore();
  const route = useAppRoute(routes.portfolioDetails);
  const { portfolioId } = route.params;
  const navigate = useAppNavigate();
  const environment = useRouteEnvironment().slug;
  const portfoliosLink = routes.portfolios.url({ params: { environment } });

  const plantList = usePhysicalPlantList(portfolioId);
  const [search, setSearch] = useState('');

  const assignPlantDisclosure = useDisclosure();

  const sortedPlants = useMemo(() => {
    if (!plantList.data) return [];
    return plantList.data.filter(plant => {
      if (search != '') {
        const searchRegex = new RegExp(search, 'i');
        const isIncludedInSearch = searchRegex.test(plant.name ?? '') || searchRegex.test(plant.internal_id ?? '');

        if (!isIncludedInSearch) return false;
      }

      return true;
    });
  }, [plantList.data, search]);

  const redirectToUserPage = () => {
    navigate(routes.insightUser, { params: { environment, user: email ?? '' } });
  };

  return (
    <>
      <PageNavigation>
        <Breadcrumbs>
          <Breadcrumb>Administration</Breadcrumb>
          <BreadcrumbLink to={portfoliosLink}>Portfolios</BreadcrumbLink>
          <PortfolioBreadcrumb />
        </Breadcrumbs>
      </PageNavigation>
      <Grid
        p={4}
        gap={4}
        height="100%"
        gridTemplate={['1fr', null, null, null, plantList.isError ? '1fr' : '1fr / 1fr 480px']}
        overflow="hidden"
      >
        <Flex flexDir="column" gap={4} maxH="100%" overflow="hidden">
          <Flex gap={5} align="center" justify="flex-end">
            <Box flex={1} minW="80">
              <Input
                addonRight={<MagnifierIcon />}
                value={search}
                onChange={text => setSearch(text)}
                placeholder="Search by name or type"
                isClearable
              />
            </Box>

            <Button size="md" leftIcon={<PlusIcon />} onClick={assignPlantDisclosure.onOpen}>
              Assign Plant
            </Button>
          </Flex>
          <BackButton text="Back to Portfolios" link={portfoliosLink} />
          <Box opacity="0.3">
            <Text fontWeight="bold" fontSize="small">
              Users with access{' '}
              <Text as="span" fontWeight="normal">
                (8 users,{' '}
                <Text as="span" color="primary">
                  3 HDG users
                </Text>
                ,{' '}
                <Text as="span" color="accent">
                  2 API Users
                </Text>
                )
              </Text>
            </Text>

            <Flex align="center" justify="space-between" gap="4" mt="2">
              <AvatarGroup size="sm" max={10} mt="1">
                <Avatar bg="primary" name="Max Mustermann" />
                <Avatar bg="primary" name="Max Mustermann" />
                <Avatar bg="primary" name="Max Mustermann" />
                <Avatar bg="accent" name="Max Mustermann" />
                <Avatar bg="accent" name="Max Mustermann" />
                <Avatar bg="accent" name="Max Mustermann" />
                <Avatar bg="secondary" name="Max Mustermann" />
                <Avatar bg="secondary" name="Max Mustermann" />
                <Avatar bg="secondary" name="Max Mustermann" />
              </AvatarGroup>

              <Flex gap="3" align="center" h="full">
                <Button leftIcon={<UserIcon />} colorScheme="secondary" isDisabled>
                  Manage Users
                </Button>
                <Button colorScheme="secondary" variant="outline" leftIcon={<BellIcon />} isDisabled>
                  Manage Notifications
                </Button>
              </Flex>
            </Flex>
          </Box>
          <Divider />
          <Box>
            <Alert status="info">
              <Text>
                A <b>Plant</b> is a physical plant, always including all components assigned.
              </Text>
              <Text>
                A <b>Plant View</b> is a subset of components in the physical plant, that allows users to manage them separately from other
                Plant Views in the same physical cascade.
              </Text>
            </Alert>
          </Box>

          <ScrollPanel fitContent maxHeight="100%">
            {plantList.isPending && (
              <Grid height="100%" overflow="hidden" templateColumns={['', '', '', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={[4, 4, 5]}>
                {repeatJsx(5, <Skeleton minH={20} flex={1} borderRadius="sm" />)}
              </Grid>
            )}

            {plantList.isError && plantList.error instanceof ApiError && plantList.error.status !== 403 && (
              <Flex align="center" justify="center" w="full">
                <EmptyPlaceholder message="Something went wrong while loading the plants." />
              </Flex>
            )}

            {plantList.error instanceof ApiError && plantList.error.status === 403 && (
              <Flex align="center" justify="center" w="full">
                <EmptyPlaceholder
                  message="You don't have the permission to view this portfolio."
                  buttonText="Manage portfolios"
                  onClick={redirectToUserPage}
                />
              </Flex>
            )}

            {plantList.isSuccess && sortedPlants.length === 0 && search.length > 0 && (
              <Flex align="center" justify="center" w="full">
                <EmptyPlaceholder
                  message="There are no plants in the current view."
                  buttonText="Clear Filters"
                  onClick={() => setSearch('')}
                />
              </Flex>
            )}

            {plantList.isSuccess && sortedPlants.length === 0 && search.length === 0 && (
              <Flex align="center" justify="center" w="full">
                <EmptyPlaceholder message="There are no plants in the current view." />
              </Flex>
            )}

            {plantList.isSuccess && sortedPlants.length !== 0 && (
              <Grid height="100%" overflow="hidden" templateColumns={['', '', '', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={[4, 4, 5]}>
                {plantList.isSuccess &&
                  sortedPlants.map(plant => {
                    return <PortfolioPlantCard key={plant.internal_id} plant={plant} isHighlighted={false} />;
                  })}
              </Grid>
            )}
          </ScrollPanel>
        </Flex>

        {plantList.isPending && <Skeleton h="full" w="full" flex={1} borderRadius="sm" />}

        {plantList.isSuccess && (
          <PlantsMap
            display={['none', 'none', 'none', 'none', 'block']}
            portfolioId={portfolioId}
            onSelectPlant={() => {}}
            onHoverPlant={() => {}}
          />
        )}
      </Grid>

      {assignPlantDisclosure.isOpen && <AssignPlantDialog onClose={assignPlantDisclosure.onClose} portfolioId={portfolioId} />}
    </>
  );
}
