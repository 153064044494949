import { Flex, Text } from '@chakra-ui/react';
import { Button, MinusIcon } from '@hydrogrid/design-system';
import { Dialog } from '../../../../../design-system/library/components/Dialog/Dialog';

type Props = {
  onClose: () => void;
  portfolioId: string;
};

export function UnassignPlantDialog({ onClose, portfolioId: _portfolioId }: Props) {
  // TODO Implement delete plant endpoint once it's ready
  // const deletePortfolio = useDeletePortfolio(portfolioId);
  // const onValidDelete = async () => {
  //   return await new Promise<boolean>(resolve => {
  //     deletePortfolio.mutate(
  //       { portfolio_id: portfolioId },
  //       {
  //         onSuccess: () => {
  //           onClose();
  //           resolve(true);
  //         },
  //         onError: () => resolve(false)
  //       }
  //     );
  //   });
  // };

  return (
    <Dialog title="Unassign Plant" isOpen={true} onClose={onClose}>
      <Text fontSize="md">Are you sure you want to unassign this Plant?</Text>

      <Flex justify="flex-end" gap={3} mt="6">
        <Button onClick={onClose} variant="outline" color="secondary">
          Cancel
        </Button>
        <Button leftIcon={<MinusIcon />} onClick={() => {}} colorScheme="error" isDisabled>
          Unassign
        </Button>
      </Flex>
    </Dialog>
  );
}
