import { Flex, IconButton } from '@chakra-ui/react';
import {
  CloseCrossIcon,
  DownstreamIcon,
  GateIcon,
  Input,
  MagnifierIcon,
  RefreshIcon,
  ReservoirIcon,
  SizeActualIcon,
  SizeFullscreenIcon,
  TurbineIcon,
  WarningTriangleIcon
} from '@hydrogrid/design-system';
import { useUpdateEffect } from '@hydrogrid/utilities/react';
import * as d3Shape from 'd3-shape';
import { motion } from 'framer-motion';
import { useEffect, useId, useMemo, useRef, useState } from 'react';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';
import type { FrontendTopology, FrontendTopologyComponent } from '../../common/api/FrontendSchemas';
import { useUserPermissions } from '../../common/auth/UserPermissions';
import styles from './TopologyGraph.module.css';
import { TopologyGraphLink } from './TopologyGraphLink';
import { TopologyGraphLoadingAnimation } from './TopologyGraphLoadingAnimation';
import { TopologyGraphNode } from './TopologyGraphNode';
import { useTopologyDragZoom } from './useTopologyDragZoom';
import { TOPOLOGY_ANIM_DURATION, useTopologyGraphData } from './useTopologyGraphData';

const DOUBLE_CLICK_TIMEOUT_MS = 300;
const lineShape = d3Shape.line().curve(d3Shape.curveMonotoneY);

interface Props {
  isPending: boolean;
  isError: boolean;
  showSearch?: boolean;

  /** Topology data as received via the API. */
  topology: FrontendTopology | undefined;

  selectedNodeIds: string[] | string;
  hoveredNodeId: string | string[] | undefined;

  onResetComponentSelection?: () => void;
  onClickComponent?: (event: React.MouseEvent, components: FrontendTopologyComponent[]) => void;
  onMouseEnterComponent?: (component: FrontendTopologyComponent | Array<FrontendTopologyComponent>, event: React.MouseEvent) => void;
  onMouseLeaveComponent?: (component: FrontendTopologyComponent, event: React.MouseEvent) => void;
}

export function TopologyGraph({
  isPending,
  isError,
  showSearch = false,
  topology,
  selectedNodeIds,
  hoveredNodeId,
  onResetComponentSelection,
  onClickComponent,
  onMouseEnterComponent,
  onMouseLeaveComponent
}: Props) {
  const generatedId = useId();
  const graphId = `TopologyGraph-${generatedId}`;
  const animationTimeoutRef = useRef(0);
  const dblClickTimeoutRef = useRef(0);
  const [isAnimationRunning, setAnimationRunning] = useState(false);
  const [foldedIds, setFoldedIds] = useState<Array<string> | 'all'>([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [search, setSearch] = useState('');

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { searchTopology } = useUserPermissions();

  const { nodes, links, graphSize, svgRef, zoomRef, graphX, graphY, graphScale } = useTopologyGraphData({
    isPending,
    topology,
    foldedIds
  });

  const filteredNode = useMemo(() => {
    const searchTerm = search.toLowerCase();
    return searchTerm
      ? nodes.find(
          node => node.data.component.id.toLowerCase().includes(searchTerm) || node.data.component.name?.toLowerCase().includes(searchTerm)
        )
      : undefined;
  }, [nodes, search]);

  // while the animation is running, we don't want to show the hover state to avoid flickering
  const isSomethingSelectedOrHovered =
    !isAnimationRunning && (hoveredNodeId !== undefined || filteredNode !== undefined || selectedNodeIds.length > 0);
  const canClickOrHover = Boolean(onClickComponent) || Boolean(onMouseEnterComponent);

  const containerRef = useRef<HTMLDivElement>(null);
  const onResize = useDebounceCallback(() => {
    zoomRef.current?.reset();
  }, 500);
  useResizeObserver({
    ref: containerRef,
    onResize
  });

  const viewportSize = { width: svgRef.current?.clientWidth ?? 0, height: svgRef.current?.clientHeight ?? 0 };
  const { startDrag, isDragging, onWheelScroll } = useTopologyDragZoom({ graphX, graphY, graphScale, viewportSize, graphSize });

  const onToggleFolded = () => {
    setAnimationRunning(true);
    setFoldedIds(oldState => {
      if (oldState === 'all' || oldState.length !== 0) {
        return [];
      }

      return 'all';
    });
  };

  useUpdateEffect(() => {
    setAnimationRunning(true);
    animationTimeoutRef.current = window.setTimeout(
      () => {
        setAnimationRunning(false);
      },
      TOPOLOGY_ANIM_DURATION * 1000 + 100
    );

    return () => {
      clearTimeout(animationTimeoutRef.current);
    };
  }, [foldedIds]);

  const isSomeNodeFolded = foldedIds === 'all' || foldedIds.length > 0;
  const allMergedNodes = nodes.filter(node => node.data.mergedNodes.length > 0);

  useEffect(() => {
    const svgElement = svgRef.current;
    const handle = (e: WheelEvent) => {
      e.preventDefault();
      onWheelScroll(e);
    };

    svgElement?.addEventListener('wheel', handle);

    return () => {
      svgElement?.removeEventListener('wheel', handle);
    };
  }, [svgRef, onWheelScroll]);

  const toggleSearch = () => {
    if (isSearchOpen) setSearch('');
    setIsSearchOpen(!isSearchOpen);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (!filteredNode || !onClickComponent) return;
      onClickComponent(event as unknown as React.MouseEvent, [filteredNode.data.component]);
      toggleSearch();
    }
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <svg
        ref={svgRef}
        className={styles.svg}
        onClick={e => {
          if (isAnimationRunning) {
            e.stopPropagation();
            return;
          }

          // when we don't click on any component, we assume the user wants to unselect the currently selected one
          onResetComponentSelection?.();
        }}
        onPointerDown={startDrag}
      >
        <defs>
          <marker
            id={`${graphId}-ARROWHEAD`}
            markerUnits="strokeWidth"
            markerWidth={8}
            markerHeight={4}
            fill="context-stroke"
            refX={6}
            refY={2}
            orient="auto"
          >
            <polygon points="0 0, 8 2, 0 4, 0.5 2" />
          </marker>
          <TurbineIcon id={`${graphId}-TURBINE`} />
          <GateIcon id={`${graphId}-GATE`} />
          <ReservoirIcon id={`${graphId}-RESERVOIR`} />
          <DownstreamIcon id={`${graphId}-DOWNSTREAM`} />
        </defs>
        <motion.g style={{ x: graphX, y: graphY, scale: graphScale, originX: '0px', originY: '0px' }}>
          <g>
            {links
              .map(({ sourceId, targetId, points, isPartOfPlant }) => {
                const isHighlighted = Array.isArray(hoveredNodeId)
                  ? hoveredNodeId.includes(sourceId) || hoveredNodeId.includes(targetId)
                  : hoveredNodeId === sourceId || hoveredNodeId === targetId;
                const pathLine = lineShape(points) ?? '';

                return [
                  ...allMergedNodes
                    .filter(node => node.data.id === targetId)
                    .map(node =>
                      node.data.mergedNodes.map(node => (
                        <g key={sourceId + ';' + node.id}>
                          <TopologyGraphLink graphId={graphId} isVisible={false} pathLine={pathLine} />
                        </g>
                      ))
                    )
                    .flat(),
                  ...allMergedNodes
                    .filter(node => node.data.id === sourceId)
                    .map(node =>
                      node.data.mergedNodes.map(node => (
                        <g key={node.id + ';' + targetId}>
                          <TopologyGraphLink graphId={graphId} isVisible={false} pathLine={pathLine} />
                        </g>
                      ))
                    )
                    .flat(),
                  <g data-testid="TopologyLink" key={sourceId + ';' + targetId} opacity={isHighlighted ? 1 : 0.3}>
                    <TopologyGraphLink graphId={graphId} isVisible pathLine={pathLine} isActive={isPartOfPlant} />
                  </g>
                ];
              })
              .flat()}
          </g>
          <g>
            {nodes
              .map(node => {
                const { data, x, y } = node;
                const isFiltered = filteredNode?.data.id.includes(data.id) ?? false;

                const isHighlighted =
                  (Array.isArray(hoveredNodeId) ? hoveredNodeId.includes(data.id) : hoveredNodeId === data.id) ||
                  selectedNodeIds.includes(data.id) ||
                  isFiltered ||
                  data.mergedNodes.some(node => selectedNodeIds.includes(node.id));
                const isMergedUnit = data.mergedNodes.length > 0;
                const mergedComponents = data.mergedNodes.map(component => {
                  return component.component;
                });

                const renderedNodes = [
                  ...data.mergedNodes.map(data => (
                    <g key={data.id}>
                      <TopologyGraphNode graphId={graphId} x={x} y={y} data={data} isVisible={false} />
                    </g>
                  )),
                  <g
                    data-testid="TopologyNode"
                    key={data.id}
                    opacity={!isSomethingSelectedOrHovered || isHighlighted ? 1 : 0.6}
                    cursor={canClickOrHover ? 'pointer' : undefined}
                    onClick={e => {
                      e.stopPropagation();

                      if (e.detail > 1) {
                        // the user clicked several time (e.g. a double click), so we don't want to execute the single click logic
                        return;
                      }

                      clearTimeout(dblClickTimeoutRef.current);
                      dblClickTimeoutRef.current = window.setTimeout(() => {
                        if (isAnimationRunning) {
                          e.preventDefault();
                          return;
                        }

                        if (isMergedUnit) {
                          onClickComponent?.(e, [data.component, ...mergedComponents]);
                          return;
                        }

                        if (onClickComponent) {
                          onClickComponent?.(e, [data.component]);
                        }
                      }, DOUBLE_CLICK_TIMEOUT_MS);
                    }}
                    onDoubleClick={e => {
                      const mergeIntoNode = nodes.find(node => node.data.id === data.mergeIntoNodeId);
                      if (!mergeIntoNode) {
                        return;
                      }
                      clearTimeout(dblClickTimeoutRef.current);

                      e.stopPropagation();
                      e.preventDefault();
                      setAnimationRunning(true);
                      onMouseLeaveComponent?.(data.component, e);

                      if (isMergedUnit) {
                        setFoldedIds(ids => {
                          if (ids === 'all') {
                            return allMergedNodes.map(node => node.data.id).filter(id => id !== data.id);
                          } else if (ids.includes(data.id)) {
                            return ids.filter(id => id !== data.id);
                          } else {
                            return [...ids, data.id];
                          }
                        });
                      } else {
                        setFoldedIds(ids => {
                          if (ids.length === allMergedNodes.length - 1) {
                            return 'all';
                          } else {
                            return [...ids, data.mergeIntoNodeId!];
                          }
                        });
                      }
                    }}
                    onMouseEnter={e => {
                      if (isDragging.current) {
                        return;
                      }

                      if (isMergedUnit) {
                        onMouseEnterComponent?.([data.component, ...data.mergedNodes.map(node => node.component)], e);
                      } else {
                        onMouseEnterComponent?.(data.component, e);
                      }
                    }}
                    onMouseLeave={e => {
                      onMouseLeaveComponent?.(data.component, e);
                    }}
                  >
                    <TopologyGraphNode
                      graphId={graphId}
                      isHighlighted={isHighlighted}
                      x={x}
                      y={y}
                      data={data}
                      isVisible
                      isFiltered={isFiltered}
                    />
                  </g>
                ];

                return renderedNodes;
              })
              .flat()}
          </g>
        </motion.g>
      </svg>
      {isError && !isPending && <WarningTriangleIcon className={styles.warning} size="xxlarge" color="destructive" />}
      <TopologyGraphLoadingAnimation loading={isPending} />
      <Flex gap="2" position="absolute" top="1.5" right="1.5" w="calc(100% - 12px)" justify="right">
        {showSearch && Boolean(searchTopology) && (
          <>
            {isSearchOpen && (
              <Input
                ref={searchInputRef}
                autoFocus
                placeholder="search"
                size="xs"
                value={search}
                onChange={s => setSearch(s)}
                onKeyDown={handleKeyDown}
                isClearable
              />
            )}

            <IconButton
              aria-label="Click to search for a component"
              title="Click to search for a component"
              icon={isSearchOpen ? <CloseCrossIcon size="font" /> : <MagnifierIcon size="font" />}
              variant="outline"
              size="sm"
              h="6.5"
              minW="6.5"
              colorScheme="secondary"
              borderColor="secondary.200"
              disabled={isPending === true}
              onClick={e => {
                e.stopPropagation();
                toggleSearch();
              }}
            />
          </>
        )}

        <IconButton
          aria-label={`Click to ${isSomeNodeFolded ? 'unfold' : 'fold'} the entire topology. OR double-click on any group to (un)fold individual groups.`}
          title={`Click to ${isSomeNodeFolded ? 'unfold' : 'fold'} the entire topology. OR double-click on any group to (un)fold individual groups.`}
          icon={isSomeNodeFolded ? <SizeFullscreenIcon size="font" /> : <SizeActualIcon size="font" />}
          variant="outline"
          size="sm"
          h="6.5"
          minW="6.5"
          colorScheme="secondary"
          borderColor="secondary.200"
          isDisabled={isPending === true}
          disabled={isPending === true}
          onClick={e => {
            e.stopPropagation();
            zoomRef.current.reset();
            onToggleFolded();
          }}
        />

        <IconButton
          aria-label="Reset the viewport and the selected components."
          title="Reset the viewport and the selected components."
          icon={<RefreshIcon size="font" />}
          variant="outline"
          size="sm"
          h="6.5"
          minW="6.5"
          colorScheme="secondary"
          borderColor="secondary.200"
          disabled={isPending === true}
          isDisabled={isPending === true}
          onClick={e => {
            e.stopPropagation();
            zoomRef.current.reset();
            onResetComponentSelection?.();
          }}
        />
      </Flex>
    </div>
  );
}
