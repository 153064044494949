import { Flex } from '@chakra-ui/react';
import type { ReactElement, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { SaveIcon, TrashIcon } from '../../../icons';
import { Button, type ButtonProps } from '../../Button/Button';
import { useConfirmDialog } from '../../Dialog/useConfirmDialog';
import { Tooltip } from '../../Tooltip/Tooltip';

interface Props {
  hideDiscardButton?: boolean;
  children?: ReactNode;
  hasPermissions?: boolean;
  submitButtonText?: ReactNode | undefined;
  submitButtonIcon?: ReactElement | undefined;
  submitButtonProps?: ButtonProps;
}

export function FormFooter({
  hideDiscardButton = false,
  children,
  submitButtonText,
  submitButtonIcon,
  hasPermissions,
  submitButtonProps = {}
}: Props) {
  const { confirmDialog } = useConfirmDialog();
  const {
    reset,
    getValues,
    formState: { isSubmitting, defaultValues, dirtyFields }
  } = useFormContext();
  const isDirty = Object.keys(dirtyFields).length > 0;

  return (
    <Flex justify="flex-end" gap={3}>
      {children}
      <Tooltip isDisabled={isDirty} label="You haven't made any changes yet">
        <Flex justify="flex-end" gap={3}>
          {!hideDiscardButton && (
            <Button
              isDisabled={isSubmitting || !isDirty}
              leftIcon={<TrashIcon />}
              colorScheme="secondary"
              onClick={async () => {
                const result = await confirmDialog({
                  title: 'Discard changes',
                  description: 'Are you sure you want to discard your changes?',
                  confirmButtonProps: { leftIcon: <TrashIcon />, children: 'Discard' }
                });
                if (!result) return;

                const resetValues = defaultValues ?? {};
                Object.keys(getValues()).forEach(key => {
                  if (resetValues[key] === undefined) {
                    // @ts-expect-error we want to set empty fields to undefined specifically
                    resetValues[key] = undefined;
                  }
                });
                reset(resetValues);
              }}
            >
              Discard
            </Button>
          )}
          <Tooltip isDisabled={!isDirty || hasPermissions !== false} label="You don't have permissions to save">
            <Button
              isDisabled={!isDirty || hasPermissions === false}
              isLoading={isSubmitting}
              leftIcon={submitButtonIcon ?? <SaveIcon />}
              type="submit"
              {...submitButtonProps}
            >
              {submitButtonText ?? 'Save'}
            </Button>
          </Tooltip>
        </Flex>
      </Tooltip>
    </Flex>
  );
}
