import { useId } from 'react';
import { type FieldValues } from 'react-hook-form';
import { Input, type InputProps } from '../../../Input/Input';
import { FormControl, extractFormControlProps, type FormControlProps } from '../../FormControl/FormControl';

interface FormInputProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, 'children'>,
    Omit<InputProps, 'children' | 'value' | 'onChange' | 'isInvalid'> {}

export function FormInput<T extends FieldValues>(props: FormInputProps<T>) {
  const { controlProps, inputProps } = extractFormControlProps<T, FormInputProps<T>>(props);
  const generatedId = useId();

  return (
    <FormControl {...controlProps} htmlFor={generatedId}>
      {({ isDisabled, value, onChange }) => (
        <Input id={generatedId} isDisabled={isDisabled} {...inputProps} value={value ?? ''} onChange={onChange} />
      )}
    </FormControl>
  );
}
