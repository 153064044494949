import { useEffect } from 'react';
import { usePreventNavigation, type ShouldPreventCallback } from '../../../routing/usePreventNavigation';
import { useConfirmDialog } from '../../Dialog/useConfirmDialog';

export function usePreventUnsavedChanges(isFormDirty: boolean, preventCallback: ShouldPreventCallback, onBeforeLeave?: () => void) {
  const { confirmDialog } = useConfirmDialog();

  useEffect(() => {
    if (!isFormDirty) {
      return;
    }

    const onBeforeUnload = function (e: BeforeUnloadEvent) {
      const confirmationMessage = 'You still have unsaved changes. If you leave before saving, your changes will be lost.';

      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isFormDirty]);

  const pendingNavigation = usePreventNavigation(preventCallback);
  useEffect(() => {
    if (pendingNavigation === undefined) {
      return;
    }

    void confirmDialog({
      title: 'You have unsaved changes!',
      description: 'Are you sure you want to leave? You will lose the changes.',
      confirmButtonProps: { children: 'Leave' }
    }).then(result => {
      if (result) {
        pendingNavigation.allow();
        onBeforeLeave?.();
      } else {
        pendingNavigation.deny();
      }
    });
  }, [pendingNavigation, confirmDialog, onBeforeLeave]);
}
