import { groupBy } from '@hydrogrid/utilities/data';
import * as d3dag from 'd3-dag';
import type { FrontendTopology, FrontendTopologyComponent } from '../../common/api/FrontendSchemas';

export type TopologySize = { width: number; height: number };

// this is the padding the graph should fit in initially when loading a new topology
const CANVAS_INITIAL_PADDING = 150;

export const NODE_RADIUS = 25;
const NODE_SIZE = [NODE_RADIUS * 2, NODE_RADIUS * 2] as const;

const shape = d3dag.tweakShape(NODE_SIZE, d3dag.shapeEllipse);

const d3dagLayout = d3dag
  .sugiyama()
  .nodeSize(NODE_SIZE)
  .gap([NODE_RADIUS * 5, NODE_RADIUS * 5])
  .tweaks([shape]);

export const createGraphLayout = (graphData: GraphNodeData[]) => {
  const builder = d3dag.graphStratify();
  const graph = builder(graphData);
  const nodes = Array.from(graph.nodes());
  const links = Array.from(graph.links()).map(link => ({
    sourceId: link.source.data.id,
    targetId: link.target.data.id,
    points: link.points,
    isPartOfPlant: link.source.data.isPartOfPlant && link.target.data.isPartOfPlant
  }));
  const { width, height: _height } = d3dagLayout(graph);
  const height = _height + 40; // we add an approximate height for the most bottom component label

  return {
    width,
    height,
    nodes,
    links
  };
};

export type GraphNodeData = {
  id: string;
  name: string;
  nodeType: 'hydrobody' | 'controlunit';
  type: string;
  isPartOfPlant: boolean;
  parentIds: string[];
  childrenIds: string[];
  component: FrontendTopologyComponent;
  mergedNodes: GraphNodeData[];
  mergeIntoNodeId?: string;
};

type LinkData = {
  sourceId: string;
  targetId: string;
  points: [number, number][];
};

// turns the data we get from the backend into the format needed for d3-dag
export function transformTopologyGraphData(topology?: FrontendTopology) {
  const data: Array<GraphNodeData> = [];

  topology?.hydro_bodies.forEach(hydroBody => {
    data.push({
      id: hydroBody.id,
      name: hydroBody.name,
      nodeType: 'hydrobody',
      type: hydroBody.type,
      isPartOfPlant: hydroBody.isPartOfPlant,
      parentIds: [],
      childrenIds: [],
      component: hydroBody,
      mergedNodes: []
    });
  });
  topology?.control_units.forEach(controlUnit => {
    data.forEach(entry => {
      if (entry.id === controlUnit.spills_to) {
        entry.parentIds.push(controlUnit.id);
      }
      if (entry.id === controlUnit.feeds_from) {
        entry.childrenIds.push(controlUnit.id);
      }
    });

    data.push({
      id: controlUnit.id,
      name: controlUnit.name ?? '',
      nodeType: 'controlunit',
      type: controlUnit.type,
      isPartOfPlant: controlUnit.isPartOfPlant,
      parentIds: [controlUnit.feeds_from],
      childrenIds: [controlUnit.spills_to],
      component: controlUnit,
      mergedNodes: []
    });
  });

  data.forEach(entry => {
    entry.parentIds = [...new Set(entry.parentIds)].sort((a, b) => a.localeCompare(b));
    entry.childrenIds = [...new Set(entry.childrenIds)].sort((a, b) => a.localeCompare(b));
  });

  return data;
}

function getControlUnitSortKey(data: GraphNodeData) {
  return (data.isPartOfPlant ? 'a' : 'b') + '_' + data.type + '_' + data.name;
}

// this function sorts nodes that are on the same level and have the same parents + children (so we don't destroy the layout generated bd d3-dag)
export function sortNodesOnSameLevel(nodes: Array<d3dag.MutGraphNode<GraphNodeData, undefined>>, links: Array<LinkData>) {
  const swappedNodeIds: Map<string, string> = new Map();

  // first we group nodes by their level (the y axis)
  const nodesGroupedByLevel = groupBy(nodes, node => {
    return node.uy ?? 0;
  });

  Object.entries(nodesGroupedByLevel).forEach(([_layerY, groupedNodesByLayer]) => {
    if (!groupedNodesByLayer) {
      return;
    }

    // here we group nodes with the same parent and children, to make sure to don't destroy the layout generated by d3-dag
    const nodesGroupedByFeedAndSpill = groupBy(groupedNodesByLayer, node => {
      return node.data.parentIds.join(',') + '_' + node.data.childrenIds.join(',');
    });

    Object.entries(nodesGroupedByFeedAndSpill).forEach(([_, groupedNodesByFeedAndSpill]) => {
      if (!groupedNodesByFeedAndSpill) {
        return;
      }

      const sortedNodeData = groupedNodesByFeedAndSpill
        .map(node => ({ id: node.data.id, x: node.ux }))
        .sort((a, b) => (a.x ?? 0) - (b.x ?? 0));
      groupedNodesByFeedAndSpill.sort((nodeA, nodeB) => {
        return getControlUnitSortKey(nodeA.data).localeCompare(getControlUnitSortKey(nodeB.data));
      });

      groupedNodesByFeedAndSpill.forEach((node, index) => {
        const sortedNode = sortedNodeData[index];
        if (sortedNode.id === node.data.id) {
          return;
        }

        swappedNodeIds.set(node.data.id, sortedNode.id);
        node.ux = sortedNode.x;
      });
    });
  });

  // now we also have to reassign the links accordingly after sorting
  const originalLinks = JSON.parse(JSON.stringify(links)) as typeof links;
  links.forEach((link, index) => {
    const originalLink = originalLinks[index];
    Array.from(swappedNodeIds.entries()).forEach(([idB, idA]) => {
      // the node with idA is now at the position where the node with idB was initially, so we make the links point to new node positions
      if (originalLink.sourceId === idA) {
        link.sourceId = idB;
      }
      if (originalLink.targetId === idA) {
        link.targetId = idB;
      }
    });
  });
}

export function getFoldedTopologyData(data: Array<GraphNodeData>, foldedIds: Array<string> | 'all') {
  const hydroBodies = JSON.parse(JSON.stringify(data.filter(({ nodeType }) => nodeType === 'hydrobody'))) as Array<GraphNodeData>;
  const controlUnits = JSON.parse(JSON.stringify(data.filter(({ nodeType }) => nodeType === 'controlunit'))) as Array<GraphNodeData>;

  const groupedControlUnits = groupBy(controlUnits, node => {
    return node.component.isPartOfPlant + '_' + node.type + '_' + node.parentIds.join(',') + '_' + node.childrenIds.join(',');
  });

  const mergedControlUnits = Object.entries(groupedControlUnits)
    .map(([_key, controlUnits]) => {
      if (!controlUnits) {
        return null;
      }

      if (controlUnits.length > 1) {
        controlUnits.forEach(controlUnit => {
          controlUnit.mergeIntoNodeId = controlUnits[0].id;
        });
      }

      const isNodeFolded = foldedIds === 'all' || foldedIds.includes(controlUnits[0].id);
      if (controlUnits.length === 1 || !isNodeFolded) {
        return [...controlUnits];
      }

      const [mergedControlUnit, ...otherControlUnits] = controlUnits;

      otherControlUnits.forEach(controlUnit => {
        hydroBodies.forEach(hydroBody => {
          hydroBody.parentIds = hydroBody.parentIds.filter(id => id !== controlUnit.id);
          hydroBody.childrenIds = hydroBody.childrenIds.filter(id => id !== controlUnit.id);
        });
      });

      mergedControlUnit.mergedNodes = otherControlUnits;
      mergedControlUnit.name = `${controlUnits.length} ${mergedControlUnit.type}S`;

      return mergedControlUnit;
    })
    .flat()
    .filter(Boolean) as Array<GraphNodeData>;

  const foldedData: Array<GraphNodeData> = [...hydroBodies, ...mergedControlUnits];

  return foldedData;
}

// calculates the initial scale so we can see the whole topology graph initially
export function calcInitialScale(viewportSize: TopologySize, graphSize: TopologySize) {
  let initialScale = 1;
  const canvasWidth = graphSize.width + CANVAS_INITIAL_PADDING;
  const canvasHeight = graphSize.height + CANVAS_INITIAL_PADDING;

  initialScale = 1 / (canvasWidth / viewportSize.width);
  if (canvasHeight * initialScale > viewportSize.height) {
    initialScale = 1 / (canvasHeight / viewportSize.height);
  }

  return initialScale;
}
