import { Marker } from '@react-google-maps/api';
import { useMemo } from 'react';
import { useProvideCoordinateToMap } from './MapMarkersContext';
import { getMarkerUrl, type MapMarkerName } from './markers';

interface MapMarkerProps {
  lat: number;
  lng: number;
  icon: MapMarkerName;
  opacity?: number;
  scale?: number;
  showOnTop?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export function MapMarker({ lat, lng, icon, opacity, scale = 1, onClick, onMouseEnter, onMouseLeave, showOnTop = false }: MapMarkerProps) {
  useProvideCoordinateToMap(lat, lng);

  const markerIcon: google.maps.Icon = useMemo(() => {
    return {
      url: getMarkerUrl(icon),
      anchor: new google.maps.Point(27.35 * scale, 50.72 * scale),
      scaledSize: new google.maps.Size(55 * scale, 55 * scale)
    };
  }, [icon, scale]);

  return (
    <Marker
      position={{ lat, lng }}
      icon={markerIcon}
      opacity={opacity}
      clickable={onClick != null || onMouseEnter != null || onMouseLeave != null}
      zIndex={icon === 'plantShadow' ? -1 : showOnTop ? 1 : 0}
      onClick={onClick}
      onMouseOver={onMouseEnter}
      onMouseOut={onMouseLeave}
    />
  );
}
