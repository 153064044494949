import { Flex, Text, useMultiStyleConfig, type CardProps } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
import { Card } from '../Card/Card';

interface HeaderSectionProps extends Omit<CardProps, 'title'> {
  title: React.ReactNode;
}

export function HeaderSection({ title, children, size, variant, ...props }: PropsWithChildren<HeaderSectionProps>) {
  const styles = useMultiStyleConfig('HeaderSection', { variant, size });

  return (
    <Card {...props} __css={styles.container}>
      <Flex align="center" justify="space-between">
        <Text sx={styles.text}>{title}</Text>
        {children}
      </Flex>
    </Card>
  );
}
