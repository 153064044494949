import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { Alert, Breadcrumb, BreadcrumbLink, Breadcrumbs, PageNavigation } from '@hydrogrid/design-system';
import { usePlantView } from '../../../common/api/hooks/CommonDataHooks';
import { routes } from '../../../common/routing/routes';
import { useAppRoute } from '../../../common/routing/useAppRoute';
import { BackButton } from '../../../components/BackButton/BackButton';
import { PlantBreadcrumb } from '../../../components/NavigationBreadcrumbs/PlantBreadcrumb';
import { PortfolioBreadcrumb } from '../../../components/NavigationBreadcrumbs/PortfolioBreadcrumb';
import { ComponentTables } from './components/ComponentTables';
import PlantViewForm from './components/PlantViewForm';
import { PlantViewTopology } from './components/PlantViewTopology';

export default function ManagePlantViewPage() {
  const route = useAppRoute(routes.managePlantView);

  const { portfolioId, plantId, environment } = route.params;
  const plantView = usePlantView(portfolioId, plantId);

  const portfoliosLink = routes.portfolios.url({ params: { environment } });

  return (
    <>
      <PageNavigation>
        <Breadcrumbs>
          <Breadcrumb>Administration</Breadcrumb>
          <BreadcrumbLink to={portfoliosLink}>Portfolios</BreadcrumbLink>
          <PortfolioBreadcrumb
            createLink={portfolioId =>
              routes.portfolioDetails.link({
                params: { environment, portfolioId }
              })
            }
          />
          <PlantBreadcrumb
            showPlantViewList
            createLink={plantId =>
              routes.managePlantView.link({
                params: { environment, portfolioId, plantId }
              })
            }
          />
        </Breadcrumbs>
      </PageNavigation>
      <Grid p={4} gap={4} height="100%" gridTemplate={['1fr', null, null, null, '1fr / 1fr 480px']} overflow="hidden">
        <Flex flexDir="column" gap={[4, null, 6]} height="100%" overflow="hidden">
          <Text fontSize="lg" fontWeight="bold">
            Manage {plantView.data?.name}
          </Text>

          <BackButton text="Back" link={routes.portfolioDetails.url({ params: { environment, portfolioId } })} />

          {plantView.isSuccess && <PlantViewForm name={plantView.data?.name ?? ''} external_id={plantView.data?.id ?? ''} />}

          <Box>
            <Alert status="info">
              <Text>
                The <b>Display Name</b> is the component name chosen by customer. The <b>External ID</b> is the external IT identifier
                (given by customer) and needs to be set for API / ECS Portfolios
              </Text>
            </Alert>
          </Box>

          <ComponentTables />
        </Flex>

        {plantId && (
          <Box
            border="1px"
            borderColor="secondary.200"
            rounded="base"
            display={['none', 'none', 'none', 'none', 'block']}
            height="100%"
            position="relative"
          >
            <PlantViewTopology plantViewId={plantId} portfolioId={portfolioId} />
          </Box>
        )}
      </Grid>
    </>
  );
}
