import { animate, useMotionValue } from 'framer-motion';
import { useEffect, useMemo, useRef } from 'react';
import type { FrontendTopology } from '../../common/api/FrontendSchemas';
import {
  calcInitialScale,
  createGraphLayout,
  getFoldedTopologyData,
  sortNodesOnSameLevel,
  transformTopologyGraphData
} from './topologyGraphUtils';

export const LINK_COLOR_ACTIVE = '#087e95';
export const LINK_COLOR_INACTIVE = '#586369';
export const TOPOLOGY_ANIM_DURATION = 0.6;

interface Props {
  isPending: boolean;
  topology: FrontendTopology | undefined;
  foldedIds: Array<string> | 'all';
}

/**
 * This hook is responsible for creating the topology graph data and holds the state for the coordinates and zoom informations.
 */
export function useTopologyGraphData({ isPending, topology, foldedIds }: Props) {
  const svgRef = useRef<SVGSVGElement>(null);
  const zoomRef = useRef<{ reset: () => void }>({ reset: () => {} });
  const graphX = useMotionValue(0);
  const graphY = useMotionValue(0);
  const graphScale = useMotionValue(1);

  const serializedTopology = JSON.stringify(topology);
  const graphData = useMemo(() => {
    const initialGraphData = transformTopologyGraphData(topology);
    const foldedGraphData = getFoldedTopologyData(initialGraphData, foldedIds);
    return foldedGraphData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializedTopology, foldedIds]);

  const serializedGraphData = JSON.stringify(graphData);
  const { nodes, links, width, height } = useMemo(() => {
    const { width, height, nodes, links } = createGraphLayout(graphData);

    sortNodesOnSameLevel(nodes, links);

    return {
      nodes,
      links,
      width,
      height
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializedGraphData]);

  useEffect(() => {
    if (isPending || !svgRef.current) {
      return;
    }

    zoomRef.current.reset = () => {
      if (svgRef.current == null) return;

      const nodeCoordiantes = nodes.filter(({ data, uy, ux }) => data.isPartOfPlant && ux !== undefined && uy !== undefined);
      const graphXMin = Math.min(...nodeCoordiantes.map(({ ux }) => ux!));
      const graphXMax = Math.max(...nodeCoordiantes.map(({ ux }) => ux!));
      const graphYMin = Math.min(...nodeCoordiantes.map(({ uy }) => uy!));
      const graphYMax = Math.max(...nodeCoordiantes.map(({ uy }) => uy!));

      const initialScale = calcInitialScale(
        { width: svgRef.current.clientWidth, height: svgRef.current.clientHeight },
        { width: graphXMax - graphXMin, height: graphYMax - graphYMin }
      );

      const translateX = -graphXMin * initialScale + svgRef.current.clientWidth / 2 - ((graphXMax - graphXMin) * initialScale) / 2;
      const translateY = -graphYMin * initialScale + svgRef.current.clientHeight / 2 - ((graphYMax - graphYMin) * initialScale) / 2;

      void animate(graphScale, Math.min(initialScale, 2), { bounce: 0, duration: TOPOLOGY_ANIM_DURATION });
      void animate(graphX, translateX, { bounce: 0, duration: TOPOLOGY_ANIM_DURATION });
      void animate(graphY, translateY, { bounce: 0, duration: TOPOLOGY_ANIM_DURATION });
    };
    zoomRef.current.reset();
  }, [isPending, width, height, graphX, graphY, graphScale, nodes]);

  return { nodes, links, graphSize: { width, height }, svgRef, zoomRef, graphX, graphY, graphScale };
}
